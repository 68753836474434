import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { validationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/validationUtilities';
import { ISport, IResults, ILeagueTable, ITable } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import { getLeagueTable } from 'store/actions/appActions';
import MainMenu from 'components/MainMenu';
import EmptyPage from 'components/EmptyPage';
import VirtualHistoryLink from 'components/VirtualHistoryLink';
import { renderTeamColourOrBadge } from 'components/Pages/Matches/MatchesUtilities';
import Config from 'theme/config.json';

import './LeagueTable.scss';

interface LeagueTableProps {
  sport: ISport;
  leagueTable: ILeagueTable;
  error: any;
  results: IResults;
  getLeagueTable: (sportId: number, weekId: number) => void;
}

const LeagueTable = (props: LeagueTableProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.sport) props.getLeagueTable(props.sport.competitionId, props.sport.currentGameWeekId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sport]);

  const getPositionChange = (previousPosition: number, currentPosition: number) => {
    if (previousPosition < currentPosition) {
      return 'decrease';
    }
    return currentPosition < previousPosition ? 'increase' : 'nochange';
  };

  const isSmallMobile = validationUtilities.isScreenSize(
    'smallMobile',
    Config.screenSizes.maxDesktopScreen,
    Config.screenSizes.mobileScreen,
    Config.screenSizes.mediumScreen,
    Config.screenSizes.smallScreen
  );

  return (
    <>
      <MainMenu />

      <div className="page">
        <div className="league-table">
          <div className="league-table__header league-table__row">
            <div className="league-table__header-col league-table__header-col--title">
              {`${t(props.sport?.competitionCode)} ${t('season')} ${props.sport?.seasonNumber}, ${t('week')} ${Math.max(props.sport?.currentGameWeekId - 1, 1)}`}
            </div>
            <div className="league-table__header-col">{t('w')}</div>
            <div className="league-table__header-col">{t('d')}</div>
            <div className="league-table__header-col">{t('l')}</div>
            <div className="league-table__header-col">{t('pts')}</div>
          </div>
          {props.leagueTable ? (
            props.leagueTable.table.map((table: ITable) => {
              return (
                <div className="league-table__row" key={table.team.teamId}>
                  <div className="league-table__col league-table__col--position">{table.stats?.position}</div>
                  <div className="league-table__col league-table__col--team-name">
                    {Config.features?.leagueTableChange ? (
                      <span
                        className={`league-table__changes-arrow
                        ${
                          table.stats?.previousPositions.length > 1
                            ? `league-table__changes-arrow--${
                                table.stats?.previousPositions.length > 1
                                  ? getPositionChange(table.stats?.previousPositions[table.stats.previousPositions.length - 2], table.stats?.position)
                                  : 'nochange'
                              }`
                            : ''
                        }`}
                      ></span>
                    ) : null}
                    {renderTeamColourOrBadge(table.team)}
                    V-
                    {isSmallMobile ? table.team.teamShortName : t(table.team.teamName)}
                  </div>
                  <div className="league-table__col">{table.stats.home?.won + table.stats.away?.won}</div>
                  <div className="league-table__col">{table.stats.home?.drawn + table.stats.away?.drawn}</div>
                  <div className="league-table__col">{table.stats.home?.lost + table.stats.away?.lost}</div>
                  <div className="league-table__col league-table__col--points">{table.stats?.points}</div>
                </div>
              );
            })
          ) : (
            <>{props.error ? <EmptyPage>{t('currently-no-match-available')}</EmptyPage> : <EmptyPage smallVersion={true}>{t('grabbing-table-now')}</EmptyPage>}</>
          )}
        </div>
      </div>
      <VirtualHistoryLink />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, results, sport, leagueTable } = state.AppReducer;

  return { error, results, sport, leagueTable };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLeagueTable: (sportId: number, weekId: number) => {
      dispatch(getLeagueTable(sportId, weekId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueTable);
