import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBetTypePriceBonus } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

interface BonusBarProps {
  totalBets: number;
  maxBets: number;
  bonusPercentage: number;
  betTypePriceBonuses: IBetTypePriceBonus[];
  calcBonusBarStep: (totalBets: number, maxBets: number) => {};
}

export default ({ totalBets, maxBets, bonusPercentage, betTypePriceBonuses, calcBonusBarStep }: BonusBarProps) => {
  const { t } = useTranslation();

  const getBonusProgress = (priceBonus: IBetTypePriceBonus, index: number) => {
    return (
      <div className={priceBonus.numLegs <= totalBets ? `bonus-bar__progress-wrapper--bonus-progress__${priceBonus.numLegs}` : ''} key={index}>
        {priceBonus.numLegs}
      </div>
    );
  };

  return (
    <div className="bonus-bar">
      <div className="bonus-bar__progress-wrapper">
        <div className="bonus-bar__progress-wrapper--matches-selected">
          <span>{Math.min(totalBets, maxBets)}</span> {t('selected')}
        </div>
        <div className="bonus-bar__progress-wrapper--bonus-text">{t('bonus')}:</div>
        <div className="bonus-bar__progress-wrapper--bonus-progress">
          <div className={1 <= totalBets ? `bonus-bar__progress-wrapper--bonus-progress__1` : ''}>1</div>
          {betTypePriceBonuses.map(getBonusProgress)}
        </div>
      </div>
      <div className={`bonus-bar__current-multiplier--${calcBonusBarStep(totalBets, maxBets)}`}>
        <div className="bonus-bar__current-multiplier--10__max">{totalBets >= maxBets ? t('max') : ''}</div>
        <div className={`bonus-bar__current-multiplier--${Math.min(totalBets, maxBets)}__number`}>{`${bonusPercentage}%`}</div>
      </div>
    </div>
  );
};
