export default {
  appStatus: 'Inited',
  sport: undefined,
  competitions: undefined,
  competitionId: undefined,
  matches: undefined,
  markets: undefined,
  selectedMarket: undefined,
  results: undefined,
  betSearch: undefined,
  betslip: undefined,
  leagueTable: undefined,
  token: undefined,
  betActive: false,
  betPlaced: false,
  bet: undefined,
  betConfig: undefined
};
