import { BetName } from '@incentivegames/ig-types/lib/enums/bet/betName';
import { BetType } from '@incentivegames/ig-types/lib/enums/bet/betType';
import { ISelection } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { WinSelectionCode } from 'constants/AppEnums';

export const getBetName = (betType: string, betName: string, t: any) => {
  if (betType === BetType.single) {
    return t(BetName.single.toLocaleLowerCase());
  } else {
    switch (betName) {
      case BetName.double:
        return t(BetName.double.toLocaleLowerCase());
      case BetName.treble:
        return t(BetName.treble.toLocaleLowerCase());
      default:
        return t(BetName.accumulator.toLocaleLowerCase());
    }
  }
};

export const getDevaluedAmount = (originalValue: number, devalueAmount: number) => {
  const devalued = devalueAmount > 0 ? originalValue / devalueAmount : originalValue;

  return Math.floor(devalued * 100) / 100;
};

export const getSelection = (selection: ISelection, showTeamName: boolean, t: (key: string) => string): string => {
  if (selection.marketCode !== '1X2') {
    return t(selection.selectionCode);
  }

  let selectionString: string | undefined;
  if (showTeamName && selection.eventHomeTeamName && selection.eventAwayTeamName) {
    switch (selection.selectionName) {
      case WinSelectionCode.HomeWin:
        selectionString = `V-${selection.eventHomeTeamName}`;
        break;
      case WinSelectionCode.AwayWin:
        selectionString = `V-${selection.eventAwayTeamName}`;
        break;
      case WinSelectionCode.Draw:
        selectionString = t('draw');
        break;
      default:
        selectionString = '';
    }
  }

  return selectionString || selection.selectionName;
};
