export default Object.freeze({
  HOME: {
    name: 'Home',
    route: '/'
  },
  MATCHES: {
    name: 'Matches',
    route: '/matches'
  },
  LIVE: {
    name: 'Live',
    route: '/live'
  },
  RESULT: {
    name: 'Result',
    route: '/result'
  },
  LEAGUETABLE: {
    name: 'League-Table',
    route: '/league-table'
  },
  BETHISTORY: {
    name: 'Bet-history',
    route: '/bet-history'
  },
  ERROR: {
    name: 'Error',
    route: '/error'
  },
  BETRECEIPT: {
    name: 'Bet-receipt',
    route: '/bet-receipt'
  }
});
