import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IBetslip } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';

import { MainMenuDisplayType } from 'constants/AppEnums';
import routes from 'constants/routes';
import Config from 'theme/config.json';
import { CompetitionSelectorPosition } from 'constants/AppEnums';
import CompetitionSelector from 'components/CompetitionSelector';
import MainMenuSelector, { IMainMenuSelectorProps } from './MainMenuSelector';
import mainMenuObject from 'resources/mainMenu';

import './MainMenu.scss';

interface MenuItem {
  label: string;
  link: string;
}

interface MainMenuProps {
  error: any;
  betslip: IBetslip;
}

const MainMenu = (props: MainMenuProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [enableBetslip, setEnableBetslip] = useState<boolean>(props.betslip && props.betslip.bets.length > 0);
  const [totalBets, setTotalBets] = useState<number>(0);

  useEffect(() => {
    if (props.betslip && props.betslip.bets.length > 0) {
      setEnableBetslip(true);
      setTotalBets(props.betslip.bets[props.betslip.bets.length - 1].selections.length);
    } else {
      setEnableBetslip(false);
      setTotalBets(0);
    }
  }, [props.betslip]);

  if (mainMenuObject) {
    if (Config.settings.mainMenuDisplayType === MainMenuDisplayType.DropDown) {
      const mainMenuSelectorProps: IMainMenuSelectorProps = {
        options: [
          { value: t('matches'), link: routes.MATCHES.route },
          { value: t('live'), link: routes.LIVE.route },
          { value: t('results'), link: routes.RESULT.route },
          { value: t('league-table'), link: routes.LEAGUETABLE.route }
        ]
      };

      if (userContext.user.isAuthenticated()) {
        mainMenuSelectorProps.options.push({ value: t('virtual-bet-history'), link: routes.BETHISTORY.route });
      }

      return (
        <div className="menu-selector-container">
          {Config.settings.competitionSelectorPosition === CompetitionSelectorPosition.MainMenu ? (
            <div className="menu-selector-container--dropdown">
              <CompetitionSelector />
            </div>
          ) : null}
          <div className="menu-selector-container--dropdown">
            <MainMenuSelector {...mainMenuSelectorProps} />
          </div>
          {Config.settings.showBetslipButton ? (
            <div className={`menu-selector-container--betslip  ${!enableBetslip ? 'menu-selector-container--inactive' : ''}`}>
              <NavLink
                to={{
                  pathname: routes.MATCHES.route,
                  state: { scrollToBetslip: true },
                  search: window.location.search
                }}
                activeClassName=""
              >
                <div className={`menu-selector-container--betslip--icon`}></div>
                <div className={`menu-selector-container--betslip--total-bets ${totalBets > 0 ? 'menu-selector-container--betslip--there-are-bets' : ''}`}>{totalBets}</div>
                <div className="menu-selector-container--betslip--text">{t('v-betslip')}</div>
              </NavLink>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <nav className="menu-wrapper">
        <ul className="menu">
          {Config.settings.competitionSelectorPosition === CompetitionSelectorPosition.MainMenu ? (
            <div className="menu__item menu__item--competitions" id="menu-item">
              <CompetitionSelector />
            </div>
          ) : null}
          <div className="menu__item menu__item--tables">
            <NavLink to={{ pathname: routes.LEAGUETABLE.route, search: window.location.search }} activeClassName="active">
              {t('tables')}
            </NavLink>
          </div>
          {Config.settings.showBetslipButton ? (
            <div className={`menu__item-betslip ${!enableBetslip ? 'menu__item-betslip--inactive' : ''}`}>
              <NavLink
                to={{
                  pathname: routes.MATCHES.route,
                  state: { scrollToBetslip: true },
                  search: window.location.search
                }}
                activeClassName=""
              >
                <div className={`menu__betslip-icon ${!enableBetslip ? 'menu__betslip-icon-inactive' : ''}`}></div>
                <div className={`menu__item--total-bets ${totalBets > 0 ? 'menu__item--there-are-bets' : ''}`}>{totalBets}</div>
                <div className="menu__item--text">{t('v-betslip')}</div>
              </NavLink>
            </div>
          ) : null}
        </ul>
        <ul className="menu">
          {mainMenuObject.map((listItem: MenuItem) => {
            return (
              <li className={`menu__item`} key={listItem.link}>
                <NavLink to={{ pathname: listItem.link, search: window.location.search }} activeClassName="active">
                  {t(listItem.label)}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: any) => {
  const { error, betslip } = state.AppReducer;

  return { error, betslip };
};

export default connect(mapStateToProps)(MainMenu);
