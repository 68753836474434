import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { currencyUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/currencyUtilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import { IBetslip, IBet, IBetConfig, ICurrencyConfig } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import { getBetslip, postBetPlace, clearBetslip, getBetConfig } from 'store/actions/appActions';
import appConstants from 'constants/appConstants';
import { selection } from 'components/Pages/Matches/MatchesUtilities';
import VirtualHistoryLink from 'components/VirtualHistoryLink';
import BetslipTable from 'components/Betslip/BetslipTable';
import Config from 'theme/config.json';

import './Betslip.scss';

/**
 * This component can be used in 2 different ways
 * IF showHistory is true, it will display all the bets with pagination
 * IF NOT, it will display only the bets related to a game week with no pagination, all at once
 */

interface BetslipProps {
  selections: selection[] | undefined;
  error: any;
  betslip: IBetslip;
  betConfig: IBetConfig;
  addOrRemoveSelection: any;
  getBetslip: (selections: string[]) => void;
  postBetPlace: (token: string, bets: IBet[], customerId?: string, countryCode?: string) => void;
  clearBetslip: () => void;
  getBetConfig: () => void;
}

const Betslip = (props: BetslipProps) => {
  const storageContext = useContext(StorageContext);
  const userContext = useContext(UserContext);

  const [currencyConfig, setCurrencyConfig] = useState<ICurrencyConfig>();

  useEffect(() => {
    props.getBetConfig(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getBetConfig]);

  useEffect(() => {
    const currencyToUse = props.betConfig?.currencyConfigs?.find((config: ICurrencyConfig) => config.currencyCode === userContext.user.currency);

    if (currencyToUse) {
      setCurrencyConfig(currencyToUse);
    } else {
      if (props.betConfig) {
        const defaultCurrencyConfig: ICurrencyConfig = {
          currencyCode: props.betConfig.betDefaultCurrencyCode,
          defaultStakes: props.betConfig.betDefaultStakes,
          maxPayout: props.betConfig.betMaxPayout,
          maxStake: props.betConfig.betMaxStake,
          minStake: props.betConfig.betMinStake,
          devalueAmount: 0
        };

        setCurrencyConfig(defaultCurrencyConfig);
      }
    }
  }, [props.betConfig, userContext.user.currency]);

  useEffect(() => {
    if (props.selections && props.selections.length > 0) {
      props.getBetslip(props.selections!.map((s: selection) => s.selectionId));
    } else {
      props.clearBetslip();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selections]);

  // TODO Right now, we take only the last bet, since that one is the combination of all. If the requirements change we would need to amend this
  const postBetPlace = (stake: number) => {
    props.betslip.bets[props.betslip.bets.length - 1].uniqueId = props.betslip.betslipId;
    props.betslip.bets[props.betslip.bets.length - 1].stakePerLine = stake;
    props.betslip.bets[props.betslip.bets.length - 1].gameType = appConstants.GAMETYPE;
    props.betslip.bets[props.betslip.bets.length - 1].currencyCode = currencyUtilities.getCurrencyByCode(
      userContext.user.currency ? userContext.user.currency : props.betConfig?.betDefaultCurrencyCode
    ).code;
    const backup = storageContext.storage.getValue('selections');

    storageContext.storage.setValue(`selections_backup`, backup, new Date(Date.now() + 180000));
    if (userContext.user.token) {
      props.postBetPlace(userContext.user.token, props.betslip.bets.slice(props.betslip.bets.length - 1), userContext.user.customerId, userContext.user.countryCode);
    }
  };

  // TODO Add proper loading
  return (
    <div className="betslip">
      {props.selections && props.selections.length > 0 && props.betslip && props.betslip.bets.length > 0 ? (
        <BetslipTable
          betslip={props.betslip}
          defaultStakes={currencyConfig?.defaultStakes ?? []}
          betMaxPayout={currencyConfig?.maxPayout ?? 0}
          betMaxStake={currencyConfig?.maxStake ?? 0}
          betMinStake={currencyConfig?.minStake ?? 0}
          betTypePriceBonuses={props.betConfig?.betTypePriceBonuses}
          currency={currencyUtilities.getCurrencyByCode(userContext.user.currency ? userContext.user.currency : props.betConfig?.betDefaultCurrencyCode).code}
          devalueAmount={currencyConfig?.devalueAmount ?? 0}
          addOrRemoveSelection={props.addOrRemoveSelection}
          postBetPlace={postBetPlace}
          error={typeof props.error === 'string' ? props.error : ''}
          betWinningsTaxRate={currencyConfig?.winningsTaxRate ?? props.betConfig?.betWinningsTaxRate}
          betStakeTaxRate={currencyConfig?.stakeTaxRate ?? props.betConfig?.betStakeTaxRate}
          showLoginButton={Config.settings.showBetslipLoginButton}
          showBetslipPlusButton={Config.settings.showBetslipPlusButton}
          betslipDisplayType={Config.settings.betslipDisplayType}
          showBetslipChevronButton={Config.settings.showBetslipChevronButton}
        />
      ) : (
        ''
      )}
      <VirtualHistoryLink />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { error, betslip, betConfig } = state.AppReducer;

  return { error, betslip, betConfig };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBetslip: (selections: string[]) => {
      dispatch(getBetslip(selections));
    },
    postBetPlace: (token: string, bets: IBet[], customerId?: string, countryCode?: string) => {
      dispatch(postBetPlace(token, bets, customerId, countryCode));
    },
    clearBetslip: () => {
      dispatch(clearBetslip());
    },
    getBetConfig: () => {
      dispatch(getBetConfig());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Betslip);
