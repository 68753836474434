import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { IBetslip, IBetConfig, IBetTypePriceBonus } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { BonusBarType } from '../../constants/AppEnums';
import SimpleBonusBar from './BonusBarView/SimpleBonusBar';
import StandardBonusBar from './BonusBarView/StandardBonusBar';
import Config from 'theme/config.json';
import './BonusBar.scss';

interface BonusBarProps {
  betslip: IBetslip;
  betConfig?: IBetConfig;
}
interface IBonusBar {
  bonusBarType: string;
  totalBets: number;
  maxBets: number;
  bonusPercentage: number;
  betTypePriceBonuses?: IBetTypePriceBonus[];
  calcBonusBarStep:(totalBets:number, maxBets:number) => {};
}

const BonusBar = ({ betslip, betConfig }: BonusBarProps) => {
  const [totalBets, setTotalBets] = useState<number>(0);
  const [bonusPercentage, setBonusPercentage] = useState<number>(0);
  const betTypePriceBonuses = betConfig?.betTypePriceBonuses;

  useEffect(() => {
    if (betslip && betslip.bets.length > 0) {
      const numberOfBets = betslip.bets[betslip.bets.length - 1].selections.length;
      setTotalBets(numberOfBets);
    } else {
      setTotalBets(0);
    }
  }, [betslip]);

  useEffect(() => {
    if (!betTypePriceBonuses?.length || totalBets < 2) {
      setBonusPercentage(0);
    } else {
      const maxBets = betTypePriceBonuses.length + 1;
      const bonusIndex = totalBets > maxBets ? betTypePriceBonuses.length - 1 : totalBets - 2;
      const bonus = betTypePriceBonuses[bonusIndex].priceBonus * 100;

      setBonusPercentage(totalBets > 1 ? Math.round(bonus) : 0);
    }
  }, [totalBets, betTypePriceBonuses]);

  const bonusBarType = (Config.settings as any)['bonusBarType'] ?? (!betTypePriceBonuses?.length ? BonusBarType.none : BonusBarType.standard);
  const maxBets = betTypePriceBonuses ? betTypePriceBonuses.length + 1 : 1;

  const calcBonusBarStep = (totalBets:number, maxBets:number) =>{
    if(Math.min(totalBets, maxBets) === maxBets){
      return 'max';
    }else{
      return Math.min(totalBets, maxBets)
    }
  }
  
  const bonusBarObj: IBonusBar = {
    bonusBarType,
    totalBets,
    maxBets,
    bonusPercentage,
    betTypePriceBonuses,
    calcBonusBarStep
  };
  return renderBonusBar(bonusBarObj);
};

const renderBonusBar = (bonusBar: IBonusBar) => {
  switch (bonusBar.bonusBarType) {
    case BonusBarType.none:
      return null;
    case BonusBarType.simple:
      return <SimpleBonusBar totalBets={bonusBar.totalBets} maxBets={bonusBar.maxBets} bonusPercentage={bonusBar.bonusPercentage} calcBonusBarStep={bonusBar.calcBonusBarStep} />;
    case BonusBarType.standard:
    default:
      return <StandardBonusBar totalBets={bonusBar.totalBets} maxBets={bonusBar.maxBets} bonusPercentage={bonusBar.bonusPercentage} betTypePriceBonuses={bonusBar.betTypePriceBonuses ?? []} calcBonusBarStep={bonusBar.calcBonusBarStep}/>;
  }
};

export const isSimpleBonusBar = () => {
  switch ((Config.settings as any)['bonusBarType'] as string) {
    case BonusBarType.simple:
      return true;

    case BonusBarType.standard:
    case BonusBarType.none:
    default:
      return false;
  }
};

const mapStateToProps = (state: any) => {
  const { betslip, betConfig } = state.AppReducer;

  return { betslip, betConfig };
};

export default connect(mapStateToProps)(BonusBar);
