import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISport } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import { getSport, getUserToken, setBetActive } from 'store/actions/appActions';
import './NextMatchRow.scss';

interface NextMatchRowProps{
  sport: ISport;
  competitionId: number;
  getSport: (sportId: number) => void;
}

//TODO: Abstract the useEffect as an util function.
const NextMatchRow = (props:NextMatchRowProps) =>{
  const [timeArray, setTimeArray] = useState(['0', '0', '0', '0']);
  const [matchTime, setMatchTime] = useState(0);

  const { t } = useTranslation();
  
  useEffect(() => {
    if (matchTime < 0) {
      setMatchTime(0);
      props.getSport(props.competitionId);
    }

    const matchCountdown = setInterval(() => {
      const matchStartTime = props.sport ? props.sport.nextMatchStartTime : 0;
      const minutes = Math.floor(matchTime / 60000);
      const seconds = Math.floor((matchTime % 60000) / 1000);

      let startTime = matchStartTime ? matchStartTime - Date.now() : 0;

      let time = ((minutes < 10 ? '0' + minutes : minutes) + '' + (seconds < 10 ? '0' + seconds : seconds)).split('');

      if (minutes >= 0 && seconds >= 0) {
        setTimeArray([time[0], time[1], time[2], time[3]]);
      } else {
        setTimeArray(['0', '0', '0', '0']);
      }
      setMatchTime(startTime);

      if (matchTime > 0 && matchTime <= props.sport.matchInterval - props.sport.matchDuration && props.sport.currentGameWeekStatus === 'inplay') props.getSport(props.competitionId);
    }, 500);

    return () => clearInterval(matchCountdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[matchTime, props] );

  const getStartTime = (time: Array<string>) => {
    return (
     <>
      {time[0] || 0 }
      {time[1] || 0} {t('min')}, {time[2] || 0}
      {time[3] || 0} {t('sec')}
    </>
    )
  }
    

  return(
    <div className="next-match-row">  
      <span className="next-match-row__label">{t('next-league-matches-start-in')}</span>
      <span className="next-match-row__timer">{getStartTime(timeArray)}</span>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { error, sport, competitionId, token, betActive } = state.AppReducer;

  return { error, sport, competitionId, token, betActive };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSport: (sportId: number) => {
      dispatch(getSport(sportId));
    },
    getUserToken: (username: string, password: string) => {
      dispatch(getUserToken(username, password));
    },
    setBetActive: (betActive: boolean) => {
      dispatch(setBetActive(betActive));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextMatchRow);

