import axios from 'axios';

const baseUrl = process.env.REACT_APP_SPORTS_API_URL || '/sportsapi';

export default class AppService {
  getCompetitors(sportId: number): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/vcompetitions?sportId=${sportId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getSport(sportId: number): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/vcompetition/${sportId}`
    });
  }

  getMatches(sportId: number, weekId: number): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/vcompetition/${sportId}/${weekId}`
    });
  }

  getResults(sportId: number, weekId: number): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/vcompetition/${sportId}/${weekId}/results`
    });
  }

  getLeagueTable(sportId: number, weekId: number): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/vcompetition/${sportId}/${weekId}/table`
    });
  }
}
