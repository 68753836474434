import React from 'react';
import { useTranslation } from 'react-i18next';

import './EmptyPage.scss';

// Small version prop renders only a small square and with no refresh button

interface EmptyPageProps {
  children: object;
  smallVersion: boolean;
}

const EmptyPage = (props: EmptyPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={`empty-page ${props.smallVersion ? 'empty-page__small' : ''}`}>
      <span>{props.children}</span>
      {!props.smallVersion ? (
        <span>
          <a href="/" className="button">
            {t('refresh')}
          </a>
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

EmptyPage.defaultProps = {
  smallVersion: false
};

export default EmptyPage;
