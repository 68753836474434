import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMarket, ISelection } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

interface IMarketsHeaderProps {
  selectedMarket: IMarket;
}

const MarketsHeader = (props: IMarketsHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="markets-header">
      <div className="markets-header__market-label">{t('market')}</div>
      <div className="markets-header__selection-wrapper">
        {props.selectedMarket?.selections.map((selection: ISelection, index: number) => {
          return (
            <div className="markets-header__selection" style={{ width: `${100 / props.selectedMarket.selections.length}%` }} key={index}>
              {t(selection.selectionName)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MarketsHeader;
