import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ISport, IResults } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import { getResults, clearBetSearch, clearResults } from 'store/actions/appActions';
import MainMenu from 'components/MainMenu';
import MatchesTable from 'components/MatchesTable';
import MyBets from 'components/MyBets';
import EmptyPage from 'components/EmptyPage';
import Config from 'theme/config.json';

import './Result.scss';

interface ResultsProps {
  error: any;
  sport: ISport;
  results: IResults;
  getResults: (sportId: number, weekId: number) => void;
  clearBetSearch: () => void;
  clearResults: () => void;
}

const Result = (props: ResultsProps) => {
  const [showInvalidWeekError, setShowInvalidWeekError] = useState<Boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      props.clearBetSearch();
      props.clearResults();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.sport) {
      if (props.sport.currentGameWeekId > 1) {
        props.getResults(props.sport.competitionId, props.sport.currentGameWeekId - 1);
        setShowInvalidWeekError(false);
      } else {
        setShowInvalidWeekError(true);
      }
    } else {
      setShowInvalidWeekError(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sport]);

  return (
    <>
      <MainMenu />
      <div className="page">
        {!showInvalidWeekError ? (
          <>
            {props.sport && props.results ? (
              <MatchesTable sport={props.sport} matches={props.results?.matches} live={false} />
            ) : (
              <>{props.error ? <EmptyPage>{t('currently-no-match-available')}</EmptyPage> : <EmptyPage smallVersion={true}>{t('grabbing-scores-now')}</EmptyPage>}</>
            )}
          </>
        ) : (
          <EmptyPage smallVersion={true}>{t('first-week-of-the-season')}</EmptyPage>
        )}
      </div>
      {props.results ? (
        <MyBets
          showHistory={false}
          fromTime={props.results.matches[0].startTime - props.results.matchInterval}
          toTime={props.results.matches[0].startTime}
          waitForSettled={!Config.settings.showUnsettledBetsOnResults}
        />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, results, sport } = state.AppReducer;

  return { error, results, sport };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getResults: (sportId: number, weekId: number) => {
      dispatch(getResults(sportId, weekId));
    },
    clearBetSearch: () => {
      dispatch(clearBetSearch());
    },
    clearResults: () => {
      dispatch(clearResults());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
