export enum TeamType {
  homeTeam = 'home',
  awayTeam = 'away'
}

export enum MarketType {
  winDrawWin = 'Win/Draw/Win'
}

export enum PageRedirectType {
  url = 0,
  postMessage = 1
}

export enum WinSelectionCode {
  HomeWin = '1',
  AwayWin = '2',
  Draw = 'X'
}

export enum CompetitionSelectorPosition {
  MainMenu = 'mainMenu',
  Header = 'header'
}

export enum CompetitionSelectorDisplayType {
  TextAndLogo = 'textAndLogo',
  TextOnly = 'textOnly',
  LogoOnly = 'logoOnly'
}

export enum MainMenuDisplayType {
  Buttons = 'buttons',
  DropDown = 'dropDown'
}

export enum BetslipDisplayType {
  Events = 'events',
  SelectionCount = 'selectionCount'
}

export enum SelectionErrorDisplayType {
  none = 'none',
  displayOnClick = 'displayOnClick',
  floatFromTop = 'floatFromTop'
}

export enum MarketDisplayType {
  tabs = 'tabs',
  dropdown = 'dropdown'
}

export enum BonusBarType {
  simple = 'simple',
  standard = 'standard',
  none = 'none'
}

export enum GameWeekStatus {
  inplay = 'inplay',
  prematch = 'prematch',
  fulltime = 'fulltime'
}

export enum ErrorMessageSize {
  small = 'small',
  big = 'big'
}
