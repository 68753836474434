import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { ResizeObserver } from 'resize-observer';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { useCurrentWidth } from '@incentivegames/ig-frontend-common/lib/hooks/useCurrentWidth';
import { IMarket, IMatch } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import { getCompetitors, getSport, setMarket } from 'store/actions/appActions';
import Routes from 'components/Routes';
import Config from 'theme/config.json';
import './app.scss';
import './shared.scss';

interface AppProps {
  error: any;
  competitionId: number;
  selectedMarket: IMarket;
  matches: IMatch[];
  getCompetitors: (sportId: number) => void;
  getSport: (sportId: number) => void;
  setMarket: (selectedMarket: IMarket) => void;
}

//TODO: Move this to its rightful place!
let sendHeight: NodeJS.Timeout;
const resizeObserver = new ResizeObserver(() => {
  clearTimeout(sendHeight);
  sendHeight = setTimeout(() => {
    communicationUtilities.sendPostMessage.resize();
  }, 50);
});

const root = document.getElementById('root')!;
resizeObserver.observe(root);

const App = (props: AppProps) => {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');
  } catch (error) {
    console.log('Error initialising GA', error);
  }

  const userContext = useContext(UserContext);

  let width = useCurrentWidth();

  // FIXME This delay is a way of waiting for the childs to render. When we build the wrapper we have to fix this
  useEffect(() => {
    setTimeout(() => {
      communicationUtilities.sendPostMessage.resize();
    }, 1500);
  }, [width]);

  useEffect(() => {
    userContext.user.addConfigHandlers(
      (token: string) => userContext.setPlayerData(token),
      (language: string) => userContext.setLanguage(language),
      // Map SLE (new Sierra Leone currency) to SLL (old Sierra Leone Leone currency) in order to handle it properly in the db
      (currency: string) => userContext.setCurrency(currency.toUpperCase() === 'SLE' ? 'SLL' : currency),
      (customerId: string) => userContext.setCustomerId(customerId),
      (countryCode: string) => userContext.setCountryCode(countryCode)
    );

    if (process.env.REACT_APP_SITE_ENV === 'dev') {
      document.body.style.overflow = 'scroll';
    }

    props.getCompetitors(1); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.competitionId) props.getSport(props.competitionId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.competitionId]);

  useEffect(() => {
    if (props.matches && !props.selectedMarket) {
      props.setMarket(props.matches[0].markets[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matches]);

  return (
    <div className="virtual-league-app">
      <Routes />
      {Config.settings.showPoweredByLogo ? (
        <div className="app-powered-by">
          <div className="powered-by"></div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { error, competitionId, matches, selectedMarket } = state.AppReducer;

  return { error, competitionId, matches, selectedMarket };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCompetitors: (sportId: number) => {
      dispatch(getCompetitors(sportId));
    },
    getSport: (sportId: number) => {
      dispatch(getSport(sportId));
    },
    setMarket: (selectedMarket: IMarket) => {
      dispatch(setMarket(selectedMarket));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
