import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { ICompetition } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { validationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/validationUtilities';

import { setCompetitionId } from 'store/actions/appActions';
import { CompetitionSelectorDisplayType } from 'constants/AppEnums';
import ArrowUp from 'theme/assets/images/arrow-up.svg';
import ArrowDown from 'theme/assets/images/arrow-down.svg';
import Config from 'theme/config.json';
import './CompetitionSelector.scss';

interface CompetitionSelectorProps {
  competitions: ICompetition[];
  competitionId: number;
  error: any;
  setCompetitionId: (competitionId: number) => void;
}

const CompetitionSelector = (props: CompetitionSelectorProps) => {
  const { t } = useTranslation();
  const [toggleoptions, setToggleoptions] = useState<boolean>(false);
  const [currentCompetition, setCurrentCompetition] = useState<ICompetition>();
  const [defaultLeague, setDefaultLeague] = useState<string | undefined>();

  const handleCompetitionIdChange = (competition: any, props: CompetitionSelectorProps) => {
    ReactGA.event({
      category: 'Competition change',
      action: competition.compName
    });

    setCurrentCompetition(competition);
    props.setCompetitionId(competition.compId);
  };

  const handleClick = (event: any) => {
    //if menu is not clicked on hide the menu
    //TODO: refactor this monster
    if (!(event.target.id === 'menu' || event.target.id === 'menu_items' || event.target.id === 'league-chevron' || event.target.id === 'icon')) {
      setToggleoptions(!toggleoptions);
    }
  };

  const getIcon = (compCode: any) => {
    if (!compCode) {
      return '';
    }

    return 'url(' + require(`../../theme/assets/images/${compCode}-icon.png`) + ')';
  };

  useEffect(() => {
    setDefaultLeague(new URLSearchParams(window.location.search).get('league') ?? '');
  }, []);

  useEffect(() => {
    if (!props?.competitions?.length) {
      return;
    }

    const defaultSelectedCompetition = props?.competitions?.find((comp: ICompetition) => comp.compCode === defaultLeague);
    if (props.competitionId) {
      const selectedCompetition = props?.competitions?.find((comp: ICompetition) => comp.compId === props.competitionId);
      setCurrentCompetition(selectedCompetition);
    } else if (defaultSelectedCompetition) {
      setCurrentCompetition(defaultSelectedCompetition);
      props.setCompetitionId(defaultSelectedCompetition.compId);
    } else {
      //sets the default competition one if no url is passed.
      setCurrentCompetition(props.competitions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLeague, props.competitions]);

  // set up/remove the listener if the menu is showing/closed
  useEffect(() => {
    if (toggleoptions) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleoptions]);

  const isMediumScreen = validationUtilities.isScreenSize(
    'mediumScreen',
    Config.screenSizes.maxDesktopScreen,
    Config.screenSizes.mobileScreen,
    Config.screenSizes.mediumScreen,
    Config.screenSizes.smallScreen
  );

  const getSelectContent = (compCode: string) => {
    switch (Config.settings.competitionSelectorDisplayType) {
      case CompetitionSelectorDisplayType.LogoOnly:
        return (
          <>
            <span id="menu_items" className="competition-selector__option--flag" style={{ backgroundImage: getIcon(compCode) }}></span>
          </>
        );
      case CompetitionSelectorDisplayType.TextAndLogo:
        return (
          <>
            <span id="menu_items" className="competition-selector__option--flag" style={{ backgroundImage: getIcon(compCode) }}></span>
            {currentCompetition ? (isMediumScreen ? t(`${currentCompetition.compCode}-short`) : t(compCode)) : ''}
          </>
        );
      case CompetitionSelectorDisplayType.TextOnly:
        return <>{currentCompetition ? (isMediumScreen ? t(`${compCode}-short`) : t(compCode)) : ''}</>;
      default:
        return (
          <>
            <span id="menu_items" className="competition-selector__option--flag" style={{ backgroundImage: getIcon(compCode) }}></span>
            {currentCompetition ? (isMediumScreen ? t(`${compCode}-short`) : t(compCode)) : ''}
          </>
        );
    }
  };

  return (
    <div className="competition-selector">
      {props.competitions && currentCompetition ? (
        <div
          className="competition-selector__wrapper"
          onClick={() => {
            setToggleoptions(!toggleoptions);
          }}
        >
          <div id="menu" className={`competition-selector__select${toggleoptions ? ' competition-selector__select--active' : ''}`}>
            <div className="competition-selector__select--text">{getSelectContent(currentCompetition.compCode)}</div>

            <div className={`competition-selector__options-wrapper${toggleoptions ? ' competition-selector__options-wrapper--active' : ''}`}>
              {props?.competitions.map((competition: ICompetition) => {
                return (
                  <div
                    id="menu_items"
                    onClick={() => {
                      handleCompetitionIdChange(competition, props);
                      setToggleoptions(!toggleoptions);
                    }}
                    key={competition.compId}
                    className={`competition-selector__option ${competition.compId === props.competitionId ? 'competition-selector__option--selected' : ''}`}
                  >
                    {getSelectContent(competition.compCode)}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="competition-selector__league-chevron-wrapper" id="league-chevron">
            <img src={toggleoptions ? ArrowUp : ArrowDown} className="competition-selector__league-chevron-wrapper--icon" id="icon" alt="arrow dropdown" />
          </div>
        </div>
      ) : (
        <span className="competition-selector--error">{t('currently-no-competitions-available')}</span>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { error, competitions, competitionId } = state.AppReducer;
  return { error, competitions, competitionId };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCompetitionId: (competitionId: number) => {
      dispatch(setCompetitionId(competitionId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionSelector);
