import React from 'react';
import { useTranslation } from 'react-i18next';

import { handleTermsAndConditionsLink } from 'utilities/commonFunctions';
import './Popup.scss';

interface PopupProps {
  title: string;
  message1: string;
  message2: string;
  buttonText: string;
  showTermsAndConditions:boolean;
  buttonFunction: () => void;
  closeFunction: () => void;
}

const Popup = (props: PopupProps) => {
  const { t } = useTranslation();

  return (
    <div className={'popup-wrapper'}>
      <div className={'popup-wrapper__popup'}>
        <div
          className={`${'popup-wrapper__popup--close'} close-icon`}
          onClick={() => {
            props.closeFunction();
          }}
        ></div>
        <div className={'popup-wrapper__popup--title'}>{t(props.title)}</div>
        <div className={'popup-wrapper__popup--text'}>{t(props.message1)}</div>
        <div className={'popup-wrapper__popup--text'}>{t(props.message2)}</div>
        <button
          className={'popup-wrapper__popup--button'}
          onClick={() => {
            props.buttonFunction();
          }}
        >
          {t(props.buttonText)}
        </button>
        {props.showTermsAndConditions ? 
          <div  className={'popup-wrapper__popup--footer-link'} onClick={() => handleTermsAndConditionsLink()}>
            {t('terms-and-conditions')}
          </div>
        :null}
      </div>
    </div>
  );
};

export default Popup;
