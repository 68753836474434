import { IErrorPopupProps } from '@incentivegames/ig-component-common/lib/components/ErrorPopup';
import iconsSprites from 'theme/assets/images/icons-sprites.svg';

export const errorUtilities = {
  getErrorPopupProps(closeIconClickHandler: () => void, displayText: string): IErrorPopupProps {
    return {
      alertIconName: `${iconsSprites}#alert-icon`,
      alertIconViewBox: '-5 -5 30 30',
      closeIconName: `${iconsSprites}#close-icon`,
      closeIconViewBox: '-10 -10 30 30',
      displayText: displayText,
      displayFont: 'MuseoSans, sans-serif',
      errorPopupBackgroundColor: '#2b2b2b',
      errorPopupTextColor: '#fff',
      errorPopupAlertIconBackgroundColor: '#A20000',
      closeIconClickHandler: closeIconClickHandler,
      textTransform: 'uppercase'
    };
  }
};
