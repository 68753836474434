export const SET_APP_STATUS = 'SET_APP_STATUS';
export const SET_BET_ACTIVE = 'SET_BET_ACTIVE';

export const GET_COMPETITIONS_SUCCESS = 'GET_COMPETITIONS_SUCCESS';
export const GET_COMPETITIONS_FAILURE = 'GET_COMPETITIONS_FAILURE';

export const SET_COMPETITION_ID = 'SET_COMPETITION_ID';

export const GET_SPORT_SUCCESS = 'GET_SPORT_SUCCESS';
export const GET_SPORT_FAILURE = 'GET_SPORT_FAILURE';

export const GET_MATCHES_SUCCESS = 'GET_MATCHES_SUCCESS';
export const GET_MATCHES_FAILURE = 'GET_MATCHES_FAILURE';

export const SET_MARKET = 'SET_MARKET';

export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_RESULTS_FAILURE = 'GET_RESULTS_FAILURE';

export const GET_BET_HISTORY_SUCCESS = 'GET_BET_HISTORY_SUCCESS';
export const GET_BET_HISTORY_FAILURE = 'GET_BET_HISTORY_FAILURE';

export const GET_BETSLIP_SUCCESS = 'GET_BETSLIP_SUCCESS';
export const GET_BETSLIP_FAILURE = 'GET_BETSLIP_FAILURE';

export const GET_LEAGUE_TABLE_SUCCESS = 'GET_LEAGUE_TABLE_SUCCESS';
export const GET_LEAGUE_TABLE_FAILURE = 'GET_LEAGUE_TABLE_FAILURE';

export const POST_BET_PLACE_SUCCESS = 'POST_BET_PLACE_SUCCESS';
export const POST_BET_PLACE_FAILURE = 'POST_BET_PLACE_FAILURE';

export const GET_BET_CONFIG_SUCCESS = 'GET_BET_CONFIG_SUCCESS';
export const GET_BET_CONFIG_FAILURE = 'GET_BET_CONFIG_FAILURE';

export const CLEAR_BET_ACTIVE = 'CLEAR_BET_ACTIVE';
export const CLEAR_BET_PLACED = 'CLEAR_BET_PLACED';
export const CLEAR_BETSLIP = 'CLEAR_BETSLIP';
export const CLEAR_BET_SEARCH = 'CLEAR_BET_SEARCH';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';

export const GET_USER_TOKEN_SUCCESS = 'GET_USER_TOKEN_SUCCESS';
export const GET_USER_TOKEN_FAILURE = 'GET_USER_TOKEN_FAILURE';
