import axios from 'axios';

const baseUrl = process.env.REACT_APP_PLAYER_API_URL || '/playerapi';

export default class PlayerService {
  getUserToken(username: string, password: string): Promise<Object> {
    return axios({
      method: 'post',
      url: `${baseUrl}/player/login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        siteId: process.env.REACT_APP_SITE_ID || '',
        username: username,
        password: password
      }
    });
  }
}
