import React from 'react';
import { NavLink } from 'react-router-dom';

import VirtualHistoryLink from 'components/VirtualHistoryLink';

import './MessageDisplay.scss';

/**
 * Component renders a message on screen
 * Can specify a button to appear and choose where it redirects the user
 * Can also specify if the Virtual History link will appear
 */

interface MessageDisplayProps {
  children: object;

  // button settings
  buttonLinkTo: string;
  buttonText: string;

  // display flags
  displayButton: boolean;
  displayBetHistoryLink: boolean;
}

const MessageDisplay = (props: MessageDisplayProps) => {
  return (
    <div>
      <span className="message-display__message">{props.children}</span>
      <br />
      {props.displayButton ? (
        <NavLink to={{ pathname: props.buttonLinkTo, search: window.location.search }} className="message-display__button">
          {props.buttonText}
        </NavLink>
      ) : (
        ''
      )}
      {props.displayBetHistoryLink ? <VirtualHistoryLink /> : ''}
    </div>
  );
};

MessageDisplay.defaultProps = {
  buttonLinkTo: '',
  buttonText: '',
  displayButton: false,
  displayBetHistoryLink: false
};

export default MessageDisplay;
