import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IBetSearch, ISelection, IBet } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { numberUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/numberUtilities';
import { dateUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/dateUtilities';
import { currencyUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/currencyUtilities';
import { BetState } from '@incentivegames/ig-types/lib/enums/bet/betState';
import { returnsRound } from '../../utilities/betslip';

import { getBetName, getDevaluedAmount, getSelection } from 'Utilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import Config from 'theme/config.json';
import { ErrorMessageSize } from 'constants/AppEnums';
import ErrorMessage from 'components/ErrorMessage';
import { eventsFinished, scoresGenerated } from 'components/MatchesTable/MatchTableUtilities';

import './MyBets.scss';

export interface BetsTableProps {
  betSearch: IBetSearch;
  showHistory: boolean;
  devalueAmount: number;
  showTeamNameInMyBets: boolean;
  betMaxPayout?: number;
}

const BetsTable = (props: BetsTableProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const locale = userContext.user.lang ?? Config.settings.defaultLocale;

  const getStatus = (settled: boolean, winningBet: boolean, betStatus?: string) => {
    if (betStatus === BetState.void) {
      return <div className={`bet__status bet__${BetState.void}`}>{t(BetState.void)}</div>;
    }
    const state = !settled ? BetState.open : winningBet ? BetState.won : BetState.lost;
    return <div className={`bet__status bet__${state}`}>{t(state)}</div>;
  };

  const getEventName = (eventName: string, homeTeamName: string | undefined, awayTeamName: string | undefined, homeTeamScore: number | undefined, awayTeamScore: number | undefined, t: any) => {
    if (!homeTeamName || !awayTeamName) {
      return eventName;
    }

    return `${t(homeTeamName)} ${homeTeamScore ?? ''} - ${awayTeamScore ?? ''} ${t(awayTeamName)}`;
  };

  const formatBetSummaryRowReturn = (bet: IBet, t: (key: string) => string, winningsTaxAmount: number, potentialReturnsValue: number) => {
    if (bet.status === BetState.void) {
      return (
        <div className="bet__summary-row bet__summary-row-return">
          <span>{t(bet.settled ? 'refunded' : 'refunding')}:</span>
          <span>{currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(bet.stakePerLine, props.devalueAmount), bet.currencyCode, locale)}</span>
        </div>
      );
    }
    return (
      <div className={`bet__summary-row bet__summary-row-return--${winningsTaxAmount ? 'total-potential-return' : 'potential-return'}`}>
        <span>{winningsTaxAmount ? t('total-potential-return') : t('potential-return')}:</span>
        <span className="bet__summary-row-value">
          {currencyUtilities.formatCurrencyWithOrientation(
            bet.settled ? getDevaluedAmount(bet.returns, props.devalueAmount) : getDevaluedAmount(potentialReturnsValue, props.devalueAmount),
            bet.currencyCode,
            locale
          )}
        </span>
      </div>
    );
  };
  const getBetValueRows = (bet: IBet, t: (key: string) => string) => {
    const totalStake = bet.numLines * bet.stakePerLine;
    let potentialReturnsValue = bet.settled ? bet.returns : bet.potentialReturns;
    let winningsTaxAmount = bet.taxAmountWinnings ?? 0;

    if (props.betMaxPayout && potentialReturnsValue > props.betMaxPayout) {
      potentialReturnsValue = Math.min(props.betMaxPayout, potentialReturnsValue);

      if (bet.taxRateWinnings) {
        winningsTaxAmount = returnsRound((potentialReturnsValue - totalStake) * bet.taxRateWinnings);
        potentialReturnsValue = returnsRound(potentialReturnsValue - winningsTaxAmount);
      }
    }

    return (
      <>
        <div className="bet__summary">
          <div className="bet__summary-row bet__summary-row--odds">
            <span>{t('total-odds')}:</span>
            <span className="bet__summary-row-value">{numberUtilities.formatNumberToLocale(bet.price.decimal, locale, true)}</span>
          </div>
          <div className="bet__summary-row bet__summary-row--stake">
            <span>{t('total-stake')}:</span>
            <span className="bet__summary-row-value">{currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(bet.stakePerLine, props.devalueAmount), bet.currencyCode, locale)}</span>
          </div>
          {bet.taxAmountWinnings ? (
            <>
              <div className="bet__summary-row bet__summary-row--winnings-tax">
                <span>
                  {t('winnings-tax')} ({bet.taxRateWinnings * 100}%):
                </span>
                <span>{currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(winningsTaxAmount, props.devalueAmount), bet.currencyCode, locale)}</span>
              </div>
            </>
          ) : null}
          {formatBetSummaryRowReturn(bet, t, winningsTaxAmount, potentialReturnsValue)}
        </div>
      </>
    );
  };

  const getTeamScores = (selections: ISelection[]) => {
    let scores = [];
    scores = selections.map((selection: ISelection) => {
      return [selection.eventHomeTeamScore, selection.eventAwayTeamScore];
    });
    return scores;
  };

  const getRows = () => {
    return props.betSearch.bets.map((bet: IBet) => (
      <div className="bet" key={bet.betReceipt}>
        <div className="bet__header">
          <div className="bet__header--name">
            {dateUtilities.formatDate(bet.betPlacedTime, t)} - {getBetName(bet.betType, bet.betName.toLocaleLowerCase(), t)} -{' '}
            {Config.settings.showExternalTransactionId && bet.betExtTxnId ? bet.betExtTxnId : bet.betReceipt.slice(0, 8)}
          </div>
          <div className="bet__header--status-container">{getStatus(bet.settled, bet.winningBet, bet.status)}</div>
        </div>

        {!scoresGenerated(getTeamScores(bet.selections), eventsFinished(bet.selections.map((selection: ISelection) => selection.eventStatus))) ? (
          <ErrorMessage errorMessage={t('scores-failed-to-generate')} size={ErrorMessageSize.small} />
        ) : null}

        {bet.selections.map((selection: ISelection) => (
          <div className="bet__selection" key={selection.selectionId}>
            <div className="bet__selection--event">
              <div className="bet__selection--pick">{getSelection(selection, props.showTeamNameInMyBets, t)}</div>
              <div className="bet__selection--type">{t(selection.marketCode)}</div>
              <div className="bet__selection--eventName">
                {selection.eventName ? getEventName(selection.eventName, selection.eventHomeTeamName, selection.eventAwayTeamName, selection.eventHomeTeamScore, selection.eventAwayTeamScore, t) : ''}
              </div>
            </div>
            <div className="bet__selection--prediction bet__status">
              {bet.status !== BetState.void ? getStatus(selection.result?.toLowerCase() === 'l' || selection.result?.toLowerCase() === 'w', selection.result?.toLowerCase() === 'w') : ''}
            </div>
            <div className="bet__selection--odds">{numberUtilities.formatNumberToLocale(selection.price.decimal, locale, true)}</div>
          </div>
        ))}
        {getBetValueRows(bet, t)}
      </div>
    ));
  };

  return (
    <div className="my-bets-table">
      <div className="my-bets-table__header">{props.showHistory ? t('bet-history') : t('my-bets')}</div>
      {getRows()}
    </div>
  );
};

export default BetsTable;
