import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IBet, ISelection } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { numberUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/numberUtilities';
import { dateUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/dateUtilities';
import { currencyUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/currencyUtilities';
import { returnsRound } from '../../../utilities/betslip';
import { getBetName, getDevaluedAmount, getSelection } from 'Utilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import Config from 'theme/config.json';

import './BetReceipt.scss';

export interface BetReceiptTableProps {
  bet: IBet;
  devalueAmount: number;
  showTeamNameInBetReceipt: boolean;
  betMaxPayout?: number;
}

const BetReceiptTable = (props: BetReceiptTableProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const locale = userContext.user.lang ?? Config.settings.defaultLocale;
  let stakesPerLine = props.bet.stakePerLine;

  if (props.bet.betStakeTaxRate) {
    stakesPerLine = stakesPerLine / (1.0 + props.bet.betStakeTaxRate);
  }

  let bonusAmount = props.bet.price.decimal * stakesPerLine * props.bet.priceBonus;
  bonusAmount = props.betMaxPayout ? Math.min(props.betMaxPayout, bonusAmount) : bonusAmount;

  const totalStake = props.bet.numLines * props.bet.stakePerLine;
  let winningsTaxAmount = props.bet.betWinningsTaxAmount;
  let potentialReturnsAmount = props.bet.potentialReturns;

  if (props.betMaxPayout && potentialReturnsAmount > props.betMaxPayout) {
    potentialReturnsAmount = props.betMaxPayout;
    if (winningsTaxAmount && props.bet.betWinningsTaxRate) {
      winningsTaxAmount = returnsRound((potentialReturnsAmount - totalStake) * props.bet.betWinningsTaxRate);
      potentialReturnsAmount = returnsRound(potentialReturnsAmount - winningsTaxAmount);
    }
  }

  const getEventName = (eventName: string, homeTeamName: string | undefined, awayTeamName: string | undefined, t: any) => {
    if (!homeTeamName || !awayTeamName) {
      return eventName;
    }

    return `${t(homeTeamName)} v ${t(awayTeamName)}`;
  };

  const getRows = () => {
    return props.bet.selections.map((selection: ISelection) => (
      <div className="bet-receipt-row" key={selection.selectionId}>
        <div className="bet-receipt-row__event">
          <div className="bet-receipt-row__event--pick">{getSelection(selection, props.showTeamNameInBetReceipt, t)}</div>
          <div className="bet-receipt-row__event--market">{t(selection.marketCode)}</div>
          <div className="bet-receipt-row__event--event-name">{selection.eventName ? getEventName(selection.eventName, selection.eventHomeTeamName, selection.eventAwayTeamName, t) : ''}</div>
        </div>
        <div className="bet-receipt-row__status">{t('open')}</div>
        <div className="bet-receipt-row__odds">{numberUtilities.formatNumberToLocale(selection.price.decimal, locale, true)}</div>
      </div>
    ));
  };

  const getInfoRows = () => {
    return (
      <>
        <div className="bet-receipt-table__gap"></div>
        <div className="bet-receipt-table__info">
          <div className="bet-receipt-table__info--left">{t('bet-id')}:</div>
          <div className="bet-receipt-table__info--right">{Config.settings.showExternalTransactionId && props.bet.betExtTxnId ? props.bet.betExtTxnId : props.bet.betReceipt.slice(0, 8)}</div>
        </div>
        <div className="bet-receipt-table__info">
          <div className="bet-receipt-table__info--left">{t('total-odds')}:</div>
          <div className="bet-receipt-table__info--right">{numberUtilities.formatNumberToLocale(props.bet.price.decimal, locale, true)}</div>
        </div>
        <div className="bet-receipt-table__info">
          <div className="bet-receipt-table__info--left">{t('total-stake')}:</div>
          <div className="bet-receipt-table__info--right">
            {Config.settings.showCurrencyOnBetReceipt
              ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(props.bet.stakePerLine, props.devalueAmount), props.bet.currencyCode, locale)
              : numberUtilities.formatNumberToLocale(getDevaluedAmount(props.bet.stakePerLine, props.devalueAmount), locale, true)}
          </div>
        </div>
        {props.bet.priceBonus ? (
          <div className="bet-receipt-table__info">
            <div className="bet-receipt-table__info--left">{t('bonus')}:</div>
            <div className="bet-receipt-table__info--right">
              {Config.settings.showCurrencyOnBetReceipt
                ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(bonusAmount, props.devalueAmount), props.bet.currencyCode, locale)
                : numberUtilities.formatNumberToLocale(getDevaluedAmount(bonusAmount, props.devalueAmount), locale, true)}
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.bet.betWinningsTaxAmount || props.bet.betStakeTaxAmount ? null : (
          <div className="bet-receipt-table__info">
            <div className="bet-receipt-table__info--left">{t('win')}:</div>
            <div className="bet-receipt-table__info--right bet-receipt-table__info--winnings">
              {Config.settings.showCurrencyOnBetReceipt
                ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(potentialReturnsAmount, props.devalueAmount), props.bet.currencyCode, locale)
                : numberUtilities.formatNumberToLocale(getDevaluedAmount(potentialReturnsAmount, props.devalueAmount), locale, true)}
            </div>
          </div>
        )}
      </>
    );
  };

  const getTaxFields = () => {
    return (
      <>
        {winningsTaxAmount ? (
          <div className="bet-receipt-table__info">
            <div className="bet-receipt-table__info--left">
              {t('winnings-tax')}({(props.bet.betWinningsTaxRate ?? 0) * 100}%):
            </div>
            <div className="bet-receipt-table__info--right bet-receipt-table__info--winnings">
              {Config.settings.showCurrencyOnBetReceipt
                ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(winningsTaxAmount ?? 0, props.devalueAmount), props.bet.currencyCode, locale)
                : numberUtilities.formatNumberToLocale(getDevaluedAmount(winningsTaxAmount ?? 0, props.devalueAmount), locale, true)}
            </div>
          </div>
        ) : null}

        {props.bet.betStakeTaxRate ? (
          <div className="bet-receipt-table__info">
            <div className="bet-receipt-table__info--left">
              {t('stake-tax')}({(props.bet.betStakeTaxRate ?? 0) * 100}%):
            </div>
            <div className="bet-receipt-table__info--right bet-receipt-table__info--winnings">
              {Config.settings.showCurrencyOnBetReceipt
                ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(props.bet.betStakeTaxAmount ?? 0, props.devalueAmount), props.bet.currencyCode, locale)
                : numberUtilities.formatNumberToLocale(getDevaluedAmount(props.bet.betStakeTaxAmount ?? 0, props.devalueAmount), locale, true)}
            </div>
          </div>
        ) : null}

        <div className="bet-receipt-table__info">
          <div className="bet-receipt-table__info--left">{t('total-potential-return')}:</div>
          <div className="bet-receipt-table__info--right bet-receipt-table__info--winnings">
            {Config.settings.showCurrencyOnBetReceipt
              ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(potentialReturnsAmount, props.devalueAmount), props.bet.currencyCode, locale)
              : numberUtilities.formatNumberToLocale(getDevaluedAmount(potentialReturnsAmount, props.devalueAmount), locale, true)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="bet-receipt-table">
      <div className="bet-receipt-table__header">
        <div className="bet-receipt-table__header--name">
          {/* FIXME We are displaying a subpart of the ID here */}
          {dateUtilities.formatDate(props.bet.betPlacedTime, t)} - {getBetName(props.bet.betType, props.bet.betName.toLocaleLowerCase(), t)} -{' '}
          {Config.settings.showExternalTransactionId && props.bet.betExtTxnId ? props.bet.betExtTxnId : props.bet.betReceipt.slice(0, 8)}
        </div>
        <div className="bet-receipt-table__header--status-container bet-receipt-row__status">{t('open')}</div>
      </div>

      {getRows()}
      {getInfoRows()}
      {props.bet.betWinningsTaxAmount || props.bet.betStakeTaxAmount ? getTaxFields() : null}
    </div>
  );
};

export default BetReceiptTable;
