interface ICalculateBetslipOptions {
  stake: number;
  odds: number;
  maxPayout: number;
  bonusPercentage: number;
  winningsTaxPercentage: number;
  stakeTaxPercentage: number;
}

export const returnsRound = (number: number) => {
  const rounded = Math.round(number * 100) / 100;

  return Number(rounded.toFixed(2));
};

export const calculateBetslipValues = (options: ICalculateBetslipOptions) => {
  const { stake, odds, maxPayout, bonusPercentage = 0, winningsTaxPercentage = 0, stakeTaxPercentage = 0 } = options;

  // 1) Apply taxes to the stake, or use it directly
  const stakeToUse = returnsRound(stakeTaxPercentage ? stake / (1.0 + stakeTaxPercentage) : stake);
  const stakeTax = returnsRound(stake - stakeToUse);

  // 2) Initial gross winnings, based on odds
  let grossWinnings = returnsRound(stakeToUse * odds);
  let potentialWinnings = 0;

  // 3) If there's a bonus to apply, add this on to the gross winnings
  let bonusWinnings = 0;
  if (bonusPercentage) {
    const bonusPercentageFraction = bonusPercentage / 100;

    grossWinnings = returnsRound(grossWinnings * (1.0 + bonusPercentageFraction));
    bonusWinnings = returnsRound(odds * stakeToUse * bonusPercentageFraction);
  }
  grossWinnings = Math.min(maxPayout, grossWinnings);
  bonusWinnings = Math.min(maxPayout, bonusWinnings);

  // 4) Start the potential winnings as the gross + bonus, before removing taxes from it
  potentialWinnings = grossWinnings;
  potentialWinnings = Math.min(maxPayout, potentialWinnings);

  // 5) Calulate the winnings taxes
  const winningsTax = returnsRound((potentialWinnings - stake) * winningsTaxPercentage);

  // 6) If there are winnings taxes configured, remove these from the potential win amount
  if (winningsTax) {
    potentialWinnings = returnsRound(potentialWinnings - winningsTax);
  }

  return {
    grossWinnings,
    stakeTax,
    winningsTax,
    potentialWinnings,
    bonusWinnings
  };
};
