import Config from 'theme/config.json';

// Use this on call that require auth. Add the ENV var REACT_APP_SITE_ENV to you local env file.
export const getHeaders = (token: string, customerId?: string, countryCode?:string) => {
  return process.env.REACT_APP_SITE_ENV === 'dev' || Config.entry.useDummyLoginButtonInProduction
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    : {
        'Content-Type': 'application/json',
        'X-IG-ExtToken': `${token}`,
        'X-IG-SiteId': process.env.REACT_APP_SITE_ID || '',
        'X-IG-ExtAcctId': customerId ?? '',
        'X-IG-CountryCode': countryCode ?? ''
      };
};
