import React from 'react';
import { ErrorMessageSize } from 'constants/AppEnums';
import iconsSprites from 'theme/assets/images/icons-sprites.svg';

import './ErrorMessage.scss';

interface IErrorMessageProps {
  errorMessage: string;
  size?: ErrorMessageSize;
}

const ErrorMessage = (props: IErrorMessageProps) => {
  return (
    <div className={`error-message${props.size ? ` error-message--${props.size}` : ''}`}>
      <svg className={`error-message__exclamation-mark${props.size ? ` error-message__exclamation-mark--${props.size}` : ''}`} viewBox="0 0 36 36">
        <use href={`${iconsSprites}#warning-icon`} />
      </svg>
      <div className="error-message__text">{props.errorMessage}</div>
    </div>
  );
};

export default ErrorMessage;
