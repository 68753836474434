import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISport } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';

import { getSport, getUserToken, setBetActive } from 'store/actions/appActions';
import Popup from 'components/Popup';
import routes from 'constants/routes';
import CompetitionSelector from 'components/CompetitionSelector';
import { CompetitionSelectorPosition } from 'constants/AppEnums';
import Config from 'theme/config.json';
import './Header.scss';

interface HeaderProps {
  sport: ISport;
  token: string;
  getUserToken: (username: string, password: string) => void;
  competitionId: number;
  betActive: boolean;
  setBetActive: (betActive: boolean) => void;
  getSport: (sportId: number) => void;
}

const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const storageContext = useContext(StorageContext);
  const history = useHistory();

  const [timeArray, setTimeArray] = useState(['0', '0', '0', '0']);
  const [matchTime, setMatchTime] = useState(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // FIXME Remove this after proper login is implemented
  const [validToken, setValidToken] = useState<boolean>(
    storageContext.storage.getValue(Config.entry.tokenCookieName) !== undefined && storageContext.storage.getValue(Config.entry.tokenCookieName) !== null
  );

  const closeFunction = () => {
    setShowPopup(false);
    if (storageContext.storage.getValue('visited-before') !== 'true') {
      storageContext.storage.setValue('visited-before', 'true', new Date(Date.now() + 86400 * 365));
    }
  };

  useEffect(() => {
    if (Config.settings.showFirstTimePopup) {
      setShowPopup(storageContext.storage.getValue('visited-before') !== 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageContext.storage]);

  useEffect(() => {
    if (props.token !== undefined && ((Config.entry.useDummyLoginButtonInLocalhost && process.env.REACT_APP_SITE_ENV === 'dev') || Config.entry.useDummyLoginButtonInProduction)) {
      storageContext.storage.setValue(Config.entry.tokenCookieName, props.token);
    }
  }, [props.token, storageContext.storage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (matchTime < 0) {
      setMatchTime(0);
      props.getSport(props.competitionId);
    }

    const matchCountdown = setInterval(() => {
      const matchStartTime = props.sport ? props.sport.nextMatchStartTime : 0;
      const minutes = Math.floor(matchTime / 60000);
      const seconds = Math.floor((matchTime % 60000) / 1000);

      let startTime = matchStartTime ? matchStartTime - Date.now() : 0;

      let time = ((minutes < 10 ? '0' + minutes : minutes) + '' + (seconds < 10 ? '0' + seconds : seconds)).split('');

      if (minutes >= 0 && seconds >= 0) {
        setTimeArray([time[0], time[1], time[2], time[3]]);
      } else {
        setTimeArray(['0', '0', '0', '0']);
      }
      setMatchTime(startTime);

      if (matchTime > 0 && matchTime <= props.sport.matchInterval - props.sport.matchDuration && props.sport.currentGameWeekStatus === 'inplay') props.getSport(props.competitionId);
    }, 500);

    return () => clearInterval(matchCountdown);
  });

  useEffect(() => {
    if (matchTime <= 0 && props.betActive) {
      props.setBetActive(false);
      history.push(routes.LIVE.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchTime]);

  const getStartTime = (time: Array<any>) => {
    return Config.settings.sportsStyleClock ? (
      <span className="header__game-state--sports">
        {time[0] || 0}
        {time[1] || 0} {time[2] || 0}
        {time[3] || 0}
        <br />
        <div className="header__game-state--sports__units">
          <div className="header__game-state--sports__units--minutes">{t('minutes')}</div>
          <div className="header__game-state--sports__units--seconds">{t('seconds')}</div>
        </div>
      </span>
    ) : (
      <span className="header__game-state--time">
        {time[0] || 0}
        {time[1] || 0}:{time[2] || 0}
        {time[3] || 0}
      </span>
    );
  };

  const logIn = () => {
    if (!validToken) {
      if (storageContext.storage.getValue(Config.entry.tokenCookieName) !== undefined && storageContext.storage.getValue(Config.entry.tokenCookieName) !== null) {
        props.getUserToken('mike@incentivegames.com', '121212');
        setValidToken(true);
      }
    } else {
      storageContext.storage.remove(Config.entry.tokenCookieName);
      setValidToken(false);
      window.location.reload();
    }
  };

  return (
    <>
      {Config.settings.showFirstTimePopup && showPopup ? (
        <Popup
          title={'welcome-to-vleague'}
          message1={'game-every-three-minutes'}
          message2={'multiple-leagues'}
          buttonText={'play-now'}
          showTermsAndConditions={Config.settings.showTermsAndConditionsInPopUp}
          buttonFunction={() => {
            closeFunction();
          }}
          closeFunction={() => {
            closeFunction();
          }}
        />
      ) : (
        ''
      )}

      <header className="header">
        {Config.settings.competitionSelectorPosition === CompetitionSelectorPosition.Header ? (
          <div className="header__competition-selector">
            <CompetitionSelector />
          </div>
        ) : null}
        <div className="header__brand">
          <div className="header__logo"></div>
          <div className="header__title">{t('virtual-league')}</div>
        </div>
        <div className="header__timer">
          {props.sport?.currentGameWeekStatus === 'prematch' || window.location.pathname !== '/live' ? (
            <>
              {Config.settings.sportsStyleClock ? (
                <div className="header__game-state">{getStartTime(timeArray)}</div>
              ) : (
                <div className="header__game-state">
                  <span className="header__game-state--text">{t('next-start')}:</span>
                  {getStartTime(timeArray)}
                </div>
              )}
              {Config.settings.gameStateIcons ? <div className="header__game-state-logo-timer"></div> : ''}
            </>
          ) : (
            ''
          )}
          {props.sport?.currentGameWeekStatus !== 'prematch' && window.location.pathname === '/live' && Config.settings.gameStateIcons ? (
            <>
              <div className="header__live-text">{t('live')}</div>
              <div className="header__game-state-logo-live"></div>
            </>
          ) : (
            ''
          )}
        </div>
        {/* // FIXME This is for testing, remove this after a proper login system is implemented */}
        {(Config.entry.useDummyLoginButtonInLocalhost && process.env.REACT_APP_SITE_ENV === 'dev') || Config.entry.useDummyLoginButtonInProduction ? (
          <button style={{ position: 'absolute', right: '150px', top: '0' }} onClick={() => logIn()}>
            {validToken ? 'LogOut' : 'Login'}
          </button>
        ) : (
          ''
        )}
      </header>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, sport, competitionId, token, betActive } = state.AppReducer;

  return { error, sport, competitionId, token, betActive };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSport: (sportId: number) => {
      dispatch(getSport(sportId));
    },
    getUserToken: (username: string, password: string) => {
      dispatch(getUserToken(username, password));
    },
    setBetActive: (betActive: boolean) => {
      dispatch(setBetActive(betActive));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
