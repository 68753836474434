import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';

import routes from 'constants/routes';

import './VirtualHistoryLink.scss';

// Displays the virtual history link if the user has logged in

const VirtualHistoryLink = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  return (
    <>
      {userContext.user.isAuthenticated() ? (
        <NavLink to={{ pathname: routes.BETHISTORY.route, search: window.location.search }} className="history-link">
          {t('virtual-bet-history')}
        </NavLink>
      ) : (
        ''
      )}
    </>
  );
};

export default VirtualHistoryLink;
