import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IMarket } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import ArrowUp from 'theme/assets/images/arrow-up.svg';
import ArrowDown from 'theme/assets/images/arrow-down.svg';
import { MarketDisplayType } from 'constants/AppEnums';
import Config from 'theme/config.json';

interface MarketsMenuProps {
  markets: IMarket[];
  selectedMarket: IMarket;
  setMarket: (selectedMarket: IMarket) => void;
}

const MarketsMenu = (props: MarketsMenuProps) => {
  const { t } = useTranslation();
  const [toggleoptions, setToggleoptions] = useState<boolean>(false);
  const [currentMarket, setCurrentMarket] = useState<string>();

  const handleClick = (event: any) => {
    //if menu is not clicked on hide the menu
    if (!(event.target.id === 'markets_menu' || event.target.id === 'markets_menu_items' || event.target.id === 'markets_menu_chevron')) {
      setToggleoptions(!toggleoptions);
    }
  };

  const handleMarketChange = (market: any) => {
    //if menu is not clicked on hide the menu
    setCurrentMarket(market.marketCode);
    props.setMarket(market);
  };

  useEffect(() => {
    if (props.markets && (!currentMarket || currentMarket.length === 0)) {
      const optionsValueText = props.markets[0].marketCode;
      if (props.selectedMarket && optionsValueText !== props.selectedMarket.marketCode) {
        setCurrentMarket(props.selectedMarket.marketCode);
      } else {
        setCurrentMarket(optionsValueText ?? ' ');
      }
    }
  }, [currentMarket, props, props.markets, props.selectedMarket]);

  useEffect(() => {
    if (toggleoptions) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleoptions]);

  const renderDropdownMarket = () => {
    return (
      <div id="markets_menu" className="markets-menu-selector" onClick={() => setToggleoptions(!toggleoptions)}>
        <div id="markets_menu" className={`markets-menu-selector__select${toggleoptions ? ' markets-menu-selector__select--active' : ''}`}>
          {t(currentMarket ?? '')}
          <div className={`markets-menu-selector__options-wrapper${toggleoptions ? ' markets-menu-selector__options-wrapper--active' : ''}`}>
            {props?.markets.map((market: any, index: number) => {
              return (
                <div
                  id="markets_menu_items"
                  className={`markets-menu-selector__option ${currentMarket === market.marketCode ? 'markets-menu-selector__option--selected' : ''}`}
                  key={market.marketId}
                  onClick={() => {
                    handleMarketChange(market);
                    setToggleoptions(!toggleoptions);
                  }}
                >
                  {t(market.marketCode)}
                </div>
              );
            })}
          </div>
        </div>
        <img src={toggleoptions ? ArrowUp : ArrowDown} id="markets_menu_chevron" className="markets-menu-selector__league-chevron" alt="arrow dropdown" />
      </div>
    );
  };

  if (props.markets) {
    if (Config.settings.marketDisplayType === MarketDisplayType.dropdown) {
      return renderDropdownMarket();
    }

    return (
      <div className="market-menu">
        {props.markets.map((market: any) => {
          return (
            <div
              className={`market-menu__item${props.selectedMarket?.marketId === market.marketId ? ' active' : ''}`}
              key={market.marketId}
              onClick={() => {
                props.setMarket(market);
              }}
            >
              {t(market.marketCode)}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default MarketsMenu;
