import React from 'react';
import { useTranslation } from 'react-i18next';

interface BonusBarProps {
  totalBets: number;
  maxBets: number;
  bonusPercentage: number;
  calcBonusBarStep:(totalBets:number, maxBets:number) => {};
}

export default ({ totalBets, maxBets, bonusPercentage, calcBonusBarStep }: BonusBarProps) => {
  const { t } = useTranslation();

  return (
    <div className="bonus-bar bonus-bar--simple">
      <div className="bonus-bar__progress-wrapper">
        <div className="bonus-bar__progress-wrapper--matches-selected">
          <span>
            {Math.min(totalBets, maxBets)}/{maxBets}
          </span>{' '}
          {t('selected')}
        </div>
        <div className="bonus-bar__progress-wrapper--bonus-text">
          {t('bonus-earned')}:<span className="bonus-bar__current-multiplier--small bonus-bar__current-multiplier--10__max"> {totalBets >= maxBets ? t('max') : ''}</span>
        </div>
      </div>
      <div className={`bonus-bar__current-multiplier--small bonus-bar__current-multiplier--${calcBonusBarStep(totalBets, maxBets)}`}>
        <div className={`bonus-bar__current-multiplier bonus-bar__current-multiplier--${Math.min(totalBets, maxBets)}__number bonus-bar__current-multiplier--small`}>{`${bonusPercentage}%`}</div>
      </div>
    </div>
  );
};
