import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import * as smoothscroll from 'smoothscroll-polyfill';
import { ISport, IMatch, IMarket, IBetslip } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';

import { getSport, setMarket } from 'store/actions/appActions';
import { MarketType, MarketDisplayType } from 'constants/AppEnums';
import MainMenu from 'components/MainMenu';
import Betslip from 'components/Betslip';
import NextMatchRow from 'components/NextMatchRow';
import {
  getSelectionsInStorage,
  mergeSelectionsWithStorage,
  setSelectionsInStorage,
  StorageSelections,
  selection,
  doesSelectionsIncludeTheSelection,
  deleteSelectionsWithSameMatch,
  parseStorageSelections,
  removeInvalidSelectionsFromStorage,
  clearSelectionInStorage
} from './MatchesUtilities';
import BonusBar, { isSimpleBonusBar } from 'components/BonusBar';
import MatchesTable from './MatchesTable';
import MarketsMenu from './MarketsMenu';
import MarketsHeader from './MarketsHeader';
import Config from 'theme/config.json';
import './Matches.scss';

interface MatchesProps {
  sport: ISport;
  competitionId: number;
  error: any;
  matches: IMatch[];
  markets: IMarket[];
  selectedMarket: IMarket;
  betPlaced: boolean;
  betslip: IBetslip;
  history: any;
  getSport: (sportId: number) => void;
  setMarket: (selectedMarket: IMarket) => void;
}

const Matches = (props: MatchesProps) => {
  const storageContext = useContext(StorageContext);
  const [selections, setSelections] = useState<selection[]>();

  const bottomRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  smoothscroll.polyfill();

  /**
   * TODO add here a function to wait until the betslip is rendered instead of a timer
   * This will scroll to the betslip if we come from the betslip menu link
   **/
  useEffect(() => {
    if (props.history.location.state?.scrollToBetslip) {
      if (bottomRef.current) {
        setTimeout(() => {
          if (bottomRef.current !== null) {
            const bottomRect = bottomRef.current.getBoundingClientRect();
            window.scrollTo({ top: bottomRect.top, behavior: 'smooth' });
            if (Config.settings.allowParentChanges) {
              window.parent.scrollTo({ top: window.parent.outerHeight, behavior: 'smooth' });
            } else {
              communicationUtilities.sendPostMessage.scrollTo(window.outerHeight);
            }
            props.history.replace();
          }
        }, 1000);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.state?.scrollToBetslip]);

  useEffect(() => {
    if (props.competitionId) {
      setSelections([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When a user selects a new competition from the CompetitionSelector component, this updates the markets.
   * We then want to update the selectedMarket in state to match the previously selected marketCode
   * to maintain the highlighted marketSelection in the MarketsMenu component.
   */
  useEffect(() => {
    if (props.markets && props.selectedMarket?.marketCode) {
      const newMarket = props.markets.find((market: IMarket) => market.marketCode === props.selectedMarket.marketCode);
      if (newMarket) props.setMarket(newMarket);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markets]);

  useEffect(() => {
    if (props.betPlaced) {
      setSelections([]);
      clearSelectionInStorage(storageContext.storage.remove);
      props.history.push('/bet-receipt');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.betPlaced, props.history]);

  // // This effect clears everything if a new week has started
  useEffect(() => {
    if (props.matches && props.matches.length > 0 && selections && selections.length > 0) {
      if (props.matches?.filter((m: IMatch) => m.matchId === selections[0].matchId).length === 0) {
        setSelections([]);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matches]);

  useEffect(() => {
    if (selections) {
      const storageSelections: StorageSelections = parseStorageSelections(getSelectionsInStorage(storageContext.storage.getValue));
      if (storageSelections) {
        if (selections.length !== storageSelections.selections.length) {
          setSelections(mergeSelectionsWithStorage(storageSelections, selections, storageContext.storage.setValue).selections);
        }
      } else {
        setSelectionsInStorage(selections, storageContext.storage.setValue);
      }
    } else {
      const storageSelections: StorageSelections = parseStorageSelections(getSelectionsInStorage(storageContext.storage.getValue));
      if (storageSelections) {
        setSelections(storageSelections.selections);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections, props.sport]);

  useEffect(() => {
    const invalidSelectionsCheck = setTimeout(() => {
      if (props.betslip && props.betslip.bets.length > 0) {
        removeInvalidSelectionsFromStorage(
          props.betslip.bets[props.betslip.bets.length - 1].selections.map((s) => s.selectionId),
          storageContext.storage
        );
      }
    }, 2000);

    return () => clearTimeout(invalidSelectionsCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.betslip]);

  const addOrRemoveSelection = (selectionId: string, add: boolean, matchId: any = undefined) => {
    const newSelection: selection = {
      selectionId: selectionId,
      matchId: matchId
    };

    if (add) {
      if (!selections) {
        setSelections([newSelection]);
      } else if (!doesSelectionsIncludeTheSelection(selections, newSelection)) {
        const newSelections = deleteSelectionsWithSameMatch(selections, matchId);
        setSelections([...newSelections, newSelection]);
        setSelectionsInStorage([...newSelections, newSelection], storageContext.storage.setValue);
      }
    } else if (selections && doesSelectionsIncludeTheSelection(selections, newSelection)) {
      const newState: selection[] = selections.filter((s: selection) => s.selectionId !== newSelection.selectionId);
      setSelections(newState);
      setSelectionsInStorage(newState, storageContext.storage.setValue);
    }
  };

  const getMatchesHeader = () => {
    const week = props.sport.currentGameWeekStatus === 'inplay' ? Math.min(props.sport.currentGameWeekId + 1, props.sport.lastGameWeekId) : props.sport.currentGameWeekId;
    const winDrawWin = props.selectedMarket?.marketName.split('/');

    if (Config.settings.marketTypeInMatchesHeader) {
      return (
        <div className="display-market-wrapper">
          <div className="display-market-wrapper__season">{`${t(props.sport.competitionCode)} ${t('season')} ${props.sport.seasonNumber}, ${t('week')} ${week}`}</div>
          {props.selectedMarket?.marketName === MarketType.winDrawWin ? (
            <div className="display-market-wrapper__market">
              <div className="display-market-wrapper__market--split">{winDrawWin[0]}</div>
              <div className="display-market-wrapper__market--split">{winDrawWin[1]}</div>
              <div className="display-market-wrapper__market--split">{winDrawWin[2]}</div>
            </div>
          ) : (
            <div className="display-market-wrapper__market">{t(props.selectedMarket?.marketName)}</div>
          )}
        </div>
      );
    } else {
      return <div>{`${t(props.sport.competitionCode)} ${t('season')} ${props.sport.seasonNumber}, ${t('week')} ${week}`}</div>;
    }
  };
  const showMarketsDropdown = Config.settings.marketDisplayType === MarketDisplayType.dropdown;

  return (
    <>
      <MainMenu />
      <>
        {Config?.settings.showNextStartMatchRow ? <NextMatchRow /> : null}
        {!isSimpleBonusBar() && <BonusBar />}
        <div className="match-page">
          {!showMarketsDropdown ? <MarketsMenu markets={props.markets} setMarket={props.setMarket} selectedMarket={props.selectedMarket} /> : null}
          {isSimpleBonusBar() && <BonusBar />}
          {props.sport ? (
            <div className="match-page__header">
              {getMatchesHeader()}
              {showMarketsDropdown ? <MarketsMenu markets={props.markets} setMarket={props.setMarket} selectedMarket={props.selectedMarket} /> : null}
            </div>
          ) : (
            ''
          )}
          {Config?.settings?.showMarketHeader && <MarketsHeader selectedMarket={props.selectedMarket} />}
          <MatchesTable
            matches={props.matches}
            selectedMarket={props.selectedMarket}
            selections={selections}
            addOrRemoveSelection={addOrRemoveSelection}
            error={props.error}
            betslip={props.betslip}
            selectionErrorDisplayType={Config.settings.selectionErrorDisplayType}
            maxSelections={Config.settings.maxNumberOfBets}
          />
        </div>
        <Betslip selections={selections} addOrRemoveSelection={addOrRemoveSelection} />
      </>
      <div ref={bottomRef}></div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, competitionId, sport, matches, markets, selectedMarket, betPlaced, betslip } = state.AppReducer;

  return { error, competitionId, sport, matches, markets, selectedMarket, betPlaced, betslip };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSport: (sportId: number) => {
      dispatch(getSport(sportId));
    },
    setMarket: (selectedMarket: IMarket) => {
      ReactGA.event({
        category: 'Market change',
        action: selectedMarket.marketName
      });
      dispatch(setMarket(selectedMarket));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Matches);
