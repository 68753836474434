import React from 'react';
import { Storage } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import { IAwayTeam, IHomeTeam } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import teamIconSprite from 'theme/assets/images/team-icons-sprites.svg';
import Config from 'theme/config.json';

export interface StorageSelections {
  selections: selection[];
}

export interface selection {
  selectionId: string;
  matchId: string;
}

export const renderTeamColourOrBadge = (team: IHomeTeam | IAwayTeam, teamColourType: string = 'home') => {
  if (Config.settings.showTeamBadge) {
    return (
      <svg className="matches-table__team-badge" viewBox="0 0 12 12">
        <use href={`${teamIconSprite}#${team.teamShortName}-logo`} />
      </svg>
    );
  } else {
    return <span className={`match-table__team-${teamColourType}--color`} style={{ backgroundColor: '#' + team.teamColour }}></span>;
  }
};

// The stored value expires after 3 minutes.
export const setSelectionsInStorage = (selections: selection[], setValue: (key: string, value: string, expiryDateTime?: Date) => void) => {
  let storageSelections: string = '';
  selections.forEach((selection) => {
    storageSelections = `${storageSelections}${storageSelections !== '' ? ';' : ''}${selection.selectionId}|${selection.matchId}`;
  });

  setValue('selections', storageSelections, new Date(Date.now() + 180000));
};

export const getSelectionsInStorage = (getValue: (key: string) => string) => {
  const storageSelections: string = getValue('selections');
  if (!storageSelections) return undefined;
  return storageSelections;
};

// It merges both selections and also updates the storage
export const mergeSelectionsWithStorage = (storageSelections: StorageSelections, selections: selection[], setValue: (key: string, value: string, expiryDateTime?: Date) => void) => {
  let mergedSelections = [...storageSelections.selections, ...selections];
  mergedSelections = mergedSelections.reduce((a: selection[], b: selection) => {
    if (!doesSelectionsIncludeTheSelection(a, b)) a.push(b);
    return a;
  }, []);
  setSelectionsInStorage(mergedSelections, setValue);
  return {
    selections: mergedSelections
  };
};

export const parseStorageSelections = (storageSelectionsString: string | undefined) => {
  let storageSelections: StorageSelections = { selections: [] };
  if (!storageSelectionsString) return storageSelections;
  let selections: string[] = storageSelectionsString.split(';');

  selections.forEach((selection) => {
    storageSelections.selections.push({
      selectionId: selection.split('|')[0],
      matchId: selection.split('|')[1]
    });
  });

  return storageSelections;
};

export const removeInvalidSelectionsFromStorage = (betslipSelections: string[], storage: Storage) => {
  const selectionsFromStorage = getSelectionsInStorage(storage.getValue);
  let storageSelections: string = '';
  if (selectionsFromStorage) {
    let selections: string[] = selectionsFromStorage.split(';');
    selections
      .filter((selection) => betslipSelections.includes(selection.split('|')[0]))
      .forEach((selection) => {
        storageSelections = `${storageSelections}${storageSelections !== '' ? ';' : ''}${selection.split('|')[0]}|${selection.split('|')[1]}`;
      });

    clearSelectionInStorage(storage.remove);
    storage.setValue('selections', storageSelections, new Date(Date.now() + 180000));
  }
};

export const doesSelectionsIncludeTheSelection = (selections: selection[], selection: selection) => {
  return selections.filter((s: selection) => s.selectionId === selection.selectionId).length !== 0;
};

export const deleteSelectionsWithSameMatch = (selections: selection[], matchId: string) => {
  return selections.filter((s: selection) => s.matchId !== matchId);
};

export const clearSelectionInStorage = (remove: (key: string) => void) => {
  remove('selections');
};

export const swapSelectionName = (selectionName: string, t: any) => {
  switch (selectionName) {
    case '1':
      return selectionName;
    case '2':
      return selectionName;
    case 'X':
      return selectionName;
    case 'Y':
      return t('yes');
    case 'N':
      return t('no');
    case 'U':
      return t('under');
    case 'O':
      return t('over');
    case '12':
      return selectionName;
    case '1X':
      return selectionName;
    case '2X':
      return selectionName;
    default:
      return selectionName;
  }
};
