import { RedirectType } from '@incentivegames/ig-types/lib/enums/communication/redirectType';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { PageRedirectType } from 'constants/AppEnums';
import Config from 'theme/config.json';

export const handleTermsAndConditionsLink = () => {
    switch (Config.settings.redirectType) {
      case PageRedirectType.postMessage:
        communicationUtilities.sendPostMessage.redirect(RedirectType.termsandconditions);
        break;
      case PageRedirectType.url:
        window.top.document.location.href = window.top.location.protocol + '//' + window.top.location.host + Config.settings.termsAndConditionsURL;
        break;
    }
  };