import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISport, IBetSearch, IMatch } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { validationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/validationUtilities';

import { clearBetActive } from 'store/actions/appActions';
import { TeamType, GameWeekStatus, ErrorMessageSize } from 'constants/AppEnums';
import routes from 'constants/routes';
import Config from 'theme/config.json';
import { renderTeamColourOrBadge } from 'components/Pages/Matches/MatchesUtilities';
import NextMatchRow from 'components/NextMatchRow';
import { eventsFinished, getTimer, scoresGenerated, selectionIsActive } from './MatchTableUtilities';
import ErrorMessage from 'components/ErrorMessage';

import './MatchesTable.scss';

export interface MatchesTableProps {
  sport: ISport;
  matches: IMatch[];
  competitionId: number;
  betActive: boolean;
  clearBetActive: () => void;
  betSearch?: IBetSearch;
  live: boolean;
}

const MatchesTable = (props: MatchesTableProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [matches, setMatches] = useState<IMatch[]>(props.matches);
  const [timeArray, setTimeArray] = useState<string[]>(getTimer(props, 0).numbers);
  const [matchTime, setMatchTime] = useState<number>(0);
  const [period, setPeriod] = useState<string>(props.live ? t(getTimer(props, 0).period) : t('full-time'));
  const [isMatchActive, setIsMatchActive] = useState<boolean>(false);

  const showSecondaryBetNextButton = Config.settings.showSecondaryBetNextButton && !props.live;

  useEffect(() => {
    setMatchTime(0);
  }, [props.sport]);

  useEffect(() => {
    const matchCountdown = setInterval(
      () => {
        let timeData = getTimer(props, matchTime);
        if (props.sport?.currentGameWeekStatus === 'inplay') {
          if (matchTime !== 0) {
            setTimeArray([timeData.numbers[0], timeData.numbers[1]]);
            setPeriod(t(timeData.period));
          }
        } else {
          setPeriod(t('full-time'));
        }
        setMatchTime(timeData.startTime);
      },
      props.live ? 500 : 50000
    );

    return () => clearInterval(matchCountdown);
  });

  useEffect(() => {
    const newMatches = [...props.matches];

    // This check is to avoid comparing matches from different weeks (it happens when you go to Live from Results)
    if (props.matches[0].matchId === matches[0].matchId) {
      for (let i = 0; i < props.matches.length; i++) {
        if (props.matches[i].homeScore !== matches[i].homeScore) newMatches[i].homeScoreChanged = true;
        if (props.matches[i].awayScore !== matches[i].awayScore) newMatches[i].awayScoreChanged = true;
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    setMatches(newMatches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matches]);

  useEffect(() => {
    if (matchTime <= 0 && isMatchActive && props.betActive !== undefined) {
      if (!props.betActive) {
        props.clearBetActive(); /* clear only if no pending bets for next game */
      }
      history.push(routes.RESULT.route);
    } else {
      setIsMatchActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchTime]);

  const notInplay = props?.sport?.currentGameWeekStatus !== 'inplay' && props?.live;
  const getStartTime = (time: string[]) => {
    return props.live ? (
      <div className="matches-table__header--game-time">
        {props.sport?.currentGameWeekStatus === GameWeekStatus.inplay ? (
          <>
            <div className="matches-table__header--number">{time[0] || 0}</div>
            <div className="matches-table__header--number">{time[1] || 1}</div>
          </>
        ) : (
          <>
            <div className={`matches-table__header--number matches-table__header--noplay ${notInplay ? 'not-live' : ''}`}>-</div>
            <div className="matches-table__header--number matches-table__header--noplay">-</div>
          </>
        )}
      </div>
    ) : (
      <div className="matches-table__header--game-time">
        <div className="matches-table__header--number">{t('f')}</div>
        <div className="matches-table__header--number">{t('t')}</div>
      </div>
    );
  };

  const isSmallMobile = validationUtilities.isScreenSize(
    'smallMobile',
    Config.screenSizes.maxDesktopScreen,
    Config.screenSizes.mobileScreen,
    Config.screenSizes.mediumScreen,
    Config.screenSizes.smallScreen
  );

  const getMatchesScore = (matches: IMatch[]) => {
    let scores = [];
    scores = matches.map((match: IMatch) => {
      return [match.homeScore, match.awayScore];
    });
    return scores;
  };

  const showFullTimeText = (Config.settings as any)['showFullTimeText'] ?? false;
  return (
    <>
      <div className="page">
        {Config.settings.showSecondaryBetNextButton ? <NextMatchRow /> : null}
        <div className="matches-table">
          <div className="matches-table__header">
            {props.sport ? (
              <>
                <div className="matches-table__header--competition">
                  <span>{`${t(props.sport.competitionCode)} `}</span>
                  <span>{`${t('season')} ${props.sport.seasonNumber}, ${t('week')} ${props.live ? props.sport.currentGameWeekId : Math.max(props.sport.currentGameWeekId - 1, 1)}`}</span>
                </div>
                <div className={`matches-table__header--game-time-wrapper ${notInplay ? 'not-live' : ''}`}>{getStartTime(timeArray)}</div>
                {props.live && period !== t('full-time') ? (
                  <div className="matches-table__header--period">
                    <div className="matches-table__header--period--mins">{period !== '' ? t('mins') : ''}</div>
                    <div className="matches-table__header--period--text">{period}</div>
                  </div>
                ) : (
                  <>
                    {Config.settings.showBetNextButton ? (
                      <div className={`matches-table__header--bet ${showSecondaryBetNextButton ? '' : 'not-displayed'}`}>
                        <NavLink to={{ pathname: routes.MATCHES.route, search: window.location.search }}>{props.live ? t('bet-now') : t('bet-next')}</NavLink>
                      </div>
                    ) : showFullTimeText ? (
                      <div className="matches-table__header--period">
                        <div className="matches-table__header--period--text">{period}</div>
                      </div>
                    ) : (
                      <div className={`matches-table__header--bet-disabled ${notInplay ? 'stretched' : ''}`}></div>
                    )}
                  </>
                )}
              </>
            ) : (
              ''
            )}
          </div>

          {!scoresGenerated(getMatchesScore(matches), eventsFinished(matches.map((match: IMatch) => match.status))) ? (
            <ErrorMessage errorMessage={t('scores-failed-to-generate')} size={ErrorMessageSize.big} />
          ) : null}
          <div className="matches-table__row-wrapper">
            {matches.map((match: IMatch) => {
              return (
                <div className={`matches-table__row${selectionIsActive(match, props) ? ' matches-table__row--selected' : ''}`} key={match.matchId}>
                  <div className={`${selectionIsActive(match, props) ? 'matches-table__home-team-name--active matches-table__home-team-name' : 'matches-table__home-team-name'}`}>
                    {renderTeamColourOrBadge(match.homeTeam, TeamType.homeTeam)}
                    V-
                    {isSmallMobile ? match.homeTeam.teamShortName : t(match.homeTeam.teamName)}
                  </div>
                  <div className={`matches-table__home-team-score${match.homeScoreChanged && match?.homeScore > 0 ? ' matches-table__home-team-score--active' : ''}`}>
                    {match.homeScore ?? '-'}
                  </div>
                  <div className={`${selectionIsActive(match, props) ? 'matches-table__dash matches-table__dash--active' : 'matches-table__dash'}`}>-</div>
                  <div className={`matches-table__away-team-score${match.awayScoreChanged && match?.awayScore > 0 ? ' matches-table__away-team-score--active' : ''}`}>
                    {match.awayScore ?? '-'}
                  </div>
                  <div className={`${selectionIsActive(match, props) ? 'matches-table__away-team-name--active matches-table__away-team-name' : 'matches-table__away-team-name'}`}>
                    V-
                    {isSmallMobile ? match.awayTeam.teamShortName : t(match.awayTeam.teamName)}
                    {renderTeamColourOrBadge(match.awayTeam, TeamType.awayTeam)}
                  </div>
                </div>
              );
            })}
          </div>
          {Config.settings.showSecondaryBetNextButton ? (
            <NavLink to={{ pathname: routes.MATCHES.route, search: window.location.search }} className="matches-table__footer-wrapper">
              <div className="matches-table__footer-wrapper--bet">{t('bet-on-next-matches')}</div>
            </NavLink>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearBetActive: () => {
      dispatch(clearBetActive());
    }
  };
};

const mapStateToProps = (state: any) => {
  const { error, betSearch, competitionId, betActive } = state.AppReducer;
  return { error, betSearch, competitionId, betActive };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchesTable);
