import React, { useEffect, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IBet, IBetConfig, ICurrencyConfig } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import { StorageContext, Storage } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';

import { clearBetPlaced, clearBetslip, getBetConfig } from 'store/actions/appActions';
import routes from 'constants/routes';
import MainMenu from 'components/MainMenu';
import BetReceiptTable from './BetReceiptTable';
import EmptyPage from 'components/EmptyPage';
import VirtualHistoryLink from 'components/VirtualHistoryLink';
import Config from 'theme/config.json';

import './BetReceipt.scss';

export interface BetReceiptProps {
  bet: IBet;
  token: string;
  betConfig: IBetConfig;
  clearBetPlaced: () => void;
  clearBetslip: () => void;
  getBetConfig: () => void;
}

const reuseBetslip = (storage: Storage) => {
  const backup = storage.getValue('selections_backup');
  storage.setValue('selections', backup, new Date(Date.now() + 180000));
};

const BetReceipt = (props: BetReceiptProps) => {
  const { t } = useTranslation();
  const storageContext = useContext(StorageContext);
  const userContext = useContext(UserContext);

  const [currencyConfig, setCurrencyConfig] = useState<ICurrencyConfig>();

  useEffect(() => {
    const currencyToUse = props.betConfig?.currencyConfigs?.find((config) => config.currencyCode === userContext.user.currency);

    if (currencyToUse) {
      setCurrencyConfig(currencyToUse);
    } else {
      if (props.betConfig) {
        const defaultCurrencyConfig: ICurrencyConfig = {
          currencyCode: props.betConfig.betDefaultCurrencyCode,
          defaultStakes: props.betConfig.betDefaultStakes,
          maxPayout: props.betConfig.betMaxPayout,
          maxStake: props.betConfig.betMaxStake,
          minStake: props.betConfig.betMinStake,
          devalueAmount: 0
        };

        setCurrencyConfig(defaultCurrencyConfig);
      }
    }
  }, [props.betConfig, userContext.user.currency]);

  useEffect(() => {
    if (Config.settings.allowParentChanges) {
      window.parent.scrollTo(0, 0);
    } else {
      communicationUtilities.sendPostMessage.scrollTo(0);
    }

    communicationUtilities.sendPostMessage.updateBalance();
    props.clearBetPlaced();
    props.clearBetslip();
    storageContext.storage.remove('stake');

    props.getBetConfig();

    return () => {
      if (props.token && props.token !== '') {
        userContext.setToken(props.token);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reuseBetslipClickHandler = () => reuseBetslip(storageContext.storage);
  return (
    <>
      <MainMenu />
      {props.bet ? (
        <div className="bet-receipt">
          <div className="bet-receipt__square">
            <div className="bet-receipt__container">
              <div className="bet-receipt__receipt">
                <div className="bet-receipt__receipt--title">{t('bet-placed-successfully!')}</div>
                <BetReceiptTable
                  bet={props.bet}
                  devalueAmount={currencyConfig?.devalueAmount ?? 0}
                  showTeamNameInBetReceipt={Config.settings.showTeamNameInBetReceipt}
                  betMaxPayout={currencyConfig?.maxPayout}
                />
              </div>
            </div>
          </div>
          <div className={Config.settings.betReceiptButtonsOnSameLine ? 'bet-receipt__buttons-wrapper' : 'bet-receipt__buttons-column'}>
            <NavLink to={{ pathname: routes.MATCHES.route, search: window.location.search }} onClick={reuseBetslipClickHandler} className="bet-receipt__play">
              {t('reuse-selections')}
            </NavLink>
            <NavLink to={{ pathname: routes.MATCHES.route, search: window.location.search }} className="bet-receipt__play bet-receipt__play--primary">
              {t('back-to-matches')}
            </NavLink>
          </div>
          <VirtualHistoryLink />
        </div>
      ) : (
        <EmptyPage smallVersion={true}>{t('you-havent-placed-any-bet')}</EmptyPage>
      )}
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { error, bet, token, betConfig } = state.AppReducer;
  return { error, bet, token, betConfig };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    clearBetPlaced: () => {
      dispatch(clearBetPlaced());
    },
    clearBetslip: () => {
      dispatch(clearBetslip());
    },
    getBetConfig: () => {
      dispatch(getBetConfig());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetReceipt);
