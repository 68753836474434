import {
  SET_APP_STATUS,
  SET_BET_ACTIVE,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAILURE,
  SET_COMPETITION_ID,
  GET_SPORT_SUCCESS,
  GET_SPORT_FAILURE,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_FAILURE,
  SET_MARKET,
  GET_RESULTS_SUCCESS,
  GET_RESULTS_FAILURE,
  GET_BET_HISTORY_SUCCESS,
  GET_BET_HISTORY_FAILURE,
  GET_BETSLIP_SUCCESS,
  GET_BETSLIP_FAILURE,
  GET_LEAGUE_TABLE_SUCCESS,
  GET_LEAGUE_TABLE_FAILURE,
  POST_BET_PLACE_SUCCESS,
  POST_BET_PLACE_FAILURE,
  CLEAR_BET_ACTIVE,
  CLEAR_BET_PLACED,
  CLEAR_BETSLIP,
  GET_BET_CONFIG_SUCCESS,
  GET_BET_CONFIG_FAILURE,
  GET_USER_TOKEN_SUCCESS,
  GET_USER_TOKEN_FAILURE,
  CLEAR_BET_SEARCH,
  CLEAR_RESULTS
} from 'store/types';
import initialState from 'store/initialState';

export default (state: any = initialState, action: any): any => {
  switch (action.type) {
    case SET_APP_STATUS:
      return {
        ...state,
        appStatus: action.status
      };
    case SET_BET_ACTIVE:
      return {
        ...state,
        betActive: action.betActive
      };

    case GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: action.competitions,
        competitionId: state.competitionId ? state.competitionId : action.competitions[0].compId
      };
    case GET_COMPETITIONS_FAILURE:
      return { ...state, error: action.error };

    case SET_COMPETITION_ID:
      return { ...state, competitionId: action.competitionId };

    case GET_SPORT_SUCCESS:
      return { ...state, sport: action.sport };
    case GET_SPORT_FAILURE:
      return { ...state, error: action.error };

    case GET_MATCHES_SUCCESS:
      return {
        ...state,
        matches: action.matches,
        markets: action.matches[0].markets
      };
    case GET_MATCHES_FAILURE:
      return { ...state, error: action.error };

    case SET_MARKET:
      return { ...state, selectedMarket: action.selectedMarket };

    case GET_RESULTS_SUCCESS:
      return { ...state, results: action.results };
    case GET_RESULTS_FAILURE:
      return { ...state, error: action.error };

    case GET_BET_HISTORY_SUCCESS:
      return { ...state, betSearch: action.betSearch };
    case GET_BET_HISTORY_FAILURE:
      return { ...state, error: action.error };

    case GET_BETSLIP_SUCCESS:
      return {
        ...state,
        betslip: action.betslip,
        betPlaced: false,
        error: undefined
      };
    case GET_BETSLIP_FAILURE:
      return { ...state, error: action.error, betPlaced: false };

    case GET_LEAGUE_TABLE_SUCCESS:
      return { ...state, leagueTable: action.leagueTable };
    case GET_LEAGUE_TABLE_FAILURE:
      return { ...state, error: action.error };

    case POST_BET_PLACE_SUCCESS:
      if (action.token) {
        return { ...state, betPlaced: true, bet: action.bet, token: action.token };
      }
      return { ...state, betPlaced: true, bet: action.bet };
    case POST_BET_PLACE_FAILURE:
      return { ...state, error: action.error, betPlaced: false };

    case GET_BET_CONFIG_SUCCESS:
      return { ...state, betConfig: action.betConfig };
    case GET_BET_CONFIG_FAILURE:
      return { ...state, error: action.error, betPlaced: false };

    case CLEAR_BET_ACTIVE:
      return { ...state, betActive: undefined };
    case CLEAR_BET_PLACED:
      return { ...state, betPlaced: undefined };
    case CLEAR_BETSLIP:
      return { ...state, betslip: undefined };
    case CLEAR_BET_SEARCH:
      return { ...state, betSearch: undefined };
    case CLEAR_RESULTS:
      return { ...state, results: undefined };

    case GET_USER_TOKEN_SUCCESS:
      return { ...state, token: action.token };
    case GET_USER_TOKEN_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};
