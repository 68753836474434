import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IBetslip, IBetTypePriceBonus, ISelection } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { numberUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/numberUtilities';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import { currencyUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/currencyUtilities';

import { BetslipDisplayType } from 'constants/AppEnums';
import { ReactComponent as PlusIcon } from '../../theme/assets/images/plus.svg';
import { ReactComponent as MinusIcon } from '../../theme/assets/images/minus.svg';
import { getDevaluedAmount, getSelection } from 'Utilities';
import { calculateBetslipValues } from 'utilities/betslip';
import { handleTermsAndConditionsLink } from 'utilities/commonFunctions';
import { ReactComponent as ArrowUp } from 'theme/assets/images/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'theme/assets/images/arrow-down.svg';
import Config from 'theme/config.json';
import './Betslip.scss';

export interface BetslipTableProps {
  betslip: IBetslip;
  defaultStakes: number[];
  betMaxPayout: number;
  betMinStake: number;
  betMaxStake: number;
  betTypePriceBonuses: IBetTypePriceBonus[];
  currency: string;
  devalueAmount: number;
  addOrRemoveSelection: any;
  postBetPlace: any;
  error: string;
  betWinningsTaxRate: number;
  betStakeTaxRate: number;
  showLoginButton: boolean;
  showBetslipPlusButton: boolean;
  betslipDisplayType: string;
  showBetslipChevronButton: boolean;
}

const BetslipTable = (props: BetslipTableProps) => {
  const storageContext = useContext(StorageContext);
  const userContext = useContext(UserContext);
  const locale = userContext.user.lang ?? Config.settings.defaultLocale;
  const currencySymbol = currencyUtilities.getCurrencyByCode(props.currency).symbol;

  const [stake, setStake] = useState<string>(storageContext.storage.getValue('stake') ?? '');
  const [winnings, setWinnings] = useState<number>(0);
  const [winningsTaxAmount, setWinningsTaxAmount] = useState<number>(0);
  const [stakeTaxAmount, setStakeTaxAmount] = useState<number>(0);
  const [totalPotentialReturn, setTotalPotentialReturn] = useState<number>(0);
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [totalBets, setTotalBets] = useState<number>(0);
  const [bonusPercentage, setBonusPercentage] = useState<number>(0);
  const [bonusAmount, setBonusAmount] = useState<number>(0);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>(false);
  const [expandedSlip, setExpandedSlip] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setUserIsAuthenticated(userContext.user.isAuthenticated());
  }, [userContext]);

  useEffect(() => {
    if (props.betslip && props.betslip.bets.length > 0) {
      const stakeAsNumber: number = parseFloat(stake.replace(',', ''));
      const odds: number = props.betslip.bets[props.betslip.bets.length - 1].price.decimal;

      const { potentialWinnings, grossWinnings, stakeTax, winningsTax, bonusWinnings } = calculateBetslipValues({
        stake: stakeAsNumber,
        odds,
        bonusPercentage,
        maxPayout: props.betMaxPayout,
        winningsTaxPercentage: props.betWinningsTaxRate || 0,
        stakeTaxPercentage: props.betStakeTaxRate || 0
      });

      setBonusAmount(Number.isNaN(bonusWinnings) ? 0 : bonusWinnings);
      setStakeTaxAmount(Number.isNaN(stakeTax) ? 0 : stakeTax);
      setWinningsTaxAmount(Number.isNaN(winningsTax) ? 0 : winningsTax);
      setWinnings(Number.isNaN(grossWinnings) ? 0 : grossWinnings);
      setTotalPotentialReturn(Number.isNaN(potentialWinnings) ? 0 : potentialWinnings);
    } else {
      setWinnings(0);
    }

    setSubmitLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stake, props.betslip, props.error, props.currency, props.betMaxPayout, props.betWinningsTaxRate, props.betStakeTaxRate, bonusPercentage]);

  useEffect(() => {
    if (stake) {
      storageContext.storage.setValue(`stake`, stake, new Date(Date.now() + 180000));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stake]);

  useEffect(() => {
    let sendHeight = setTimeout(() => {
      communicationUtilities.sendPostMessage.resize();
    }, 200);

    setTotalBets(props.betslip && props.betslip.bets.length > 0 ? props.betslip.bets[props.betslip.bets.length - 1].selections.length : 0);

    return () => {
      clearTimeout(sendHeight);
    };
  }, [props.betslip]);

  useEffect(() => {
    setBonusPercentage(totalBets > 1 ? Math.round(props.betslip.bets[props.betslip.bets.length - 1].priceBonus * 100) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalBets]);

  const handleCancelIconClick = (selectionId: undefined | string) => {
    if (selectionId) props.addOrRemoveSelection(selectionId, false);
  };

  const handlePlaceBetButtonClick = () => {
    setSubmitLoading(true);
    const stakeAsNumber = parseFloat(stake.replace(',', ''));
    const stakeToUse = props.devalueAmount > 0 ? (stakeAsNumber * props.devalueAmount).toString() : stake;
    props.postBetPlace(stakeToUse);
  };

  const handleAutoStakeClick = (stake: number) => {
    //eslint-disable-next-line
    const parsedAutoStake = numberUtilities.formatNumberToLocale(getDevaluedAmount(stake, props.devalueAmount), Config.settings.defaultLocale, true, true).replace(/\,/g, '').toString();
    setStake(parsedAutoStake);
  };

  const handleBetTextBoxChange = (event: any, maxStakeAmount: number) => {
    if (event.target.value.length <= 0) {
      setStake('');
      return;
    }

    if (event.target.value > maxStakeAmount) {
      setStake(maxStakeAmount.toString());
      return;
    }

    const regex = /^\d+\.?\d{0,2}$/;
    if (regex.test(event.target.value)) {
      setStake(event.target.value);
    }
  };

  const getErrorMessage = () => {
    switch (props.error) {
      case 'stake-high':
        const maxStake = getDevaluedAmount(props.betMaxStake, props.devalueAmount);
        const maxStakeString = maxStake > 999999 ? (maxStake / 1000000).toString() + 'm' : maxStake.toString();
        return `${t(props.error)}${props.currency} ${maxStakeString}`;
      case 'stake-low':
        return `${t(props.error)}${props.currency} ${getDevaluedAmount(props.betMinStake, props.devalueAmount)}`;
      default:
        return t(props.error);
    }
  };

  const loginClickHandler = () => communicationUtilities.login(Config.entry.type, Config.entry.loginURL);

  const renderPlaceBetButton = () => {
    if (!userIsAuthenticated) {
      return (
        <>
          {!props.showLoginButton ? (
            <div className="bet-form__static-text">{t('login-and-play')}</div>
          ) : (
            <button onClick={loginClickHandler} className="bet-form__submit">
              {t('login-and-play')}
            </button>
          )}
        </>
      );
    } else if (isSubmitLoading) {
      return (
        <button className="bet-form__submit bet-form__submit--loading">
          <span></span>
          <span></span>
          <span></span>
        </button>
      );
    } else if (stake.length > 0 && parseFloat(stake) > 0) {
      return (
        <button onClick={handlePlaceBetButtonClick} className="bet-form__submit">
          {t('bet-now')}
        </button>
      );
    } else {
      return <button className="bet-form__submit bet-form__submit--invalid-stake">{t('bet-now')}</button>;
    }
  };

  const addPlaceholderColumn = (showBetslipButton: boolean) => {
    if (showBetslipButton && (props.betWinningsTaxRate || props.betStakeTaxRate)) {
      return <span className="bet-form__row--placeholder bet-form__bonus-row"></span>;
    } else {
      return null;
    }
  };

  const getRows = () => {
    const getEventName = (eventName: string, homeTeamName: string | undefined, awayTeamName: string | undefined, t: any) => {
      if (!homeTeamName || !awayTeamName) {
        return eventName;
      }

      return `${t(homeTeamName)} v ${t(awayTeamName)}`;
    };

    switch (props.betslipDisplayType) {
      case BetslipDisplayType.SelectionCount:
        return (
          <div className="bet-selections--wrapper">
            <div className="bet-selections__selection-count">
              <div className="bet-selections__selection-count--text">{t('selections')}</div>
              <div className="bet-selections__selection-count--colon">:</div>
              <div className="bet-selections__selection-count--value">{totalBets}</div>
            </div>
            <div className="bet-selections__total-odds">
              <div className="bet-selections__total-odds--text">{t('total-odds')}</div>
              <div className="bet-selections__selection-count--colon">:</div>
              <div className="bet-selections__total-odds--value">
                {numberUtilities.formatNumberToLocale(props.betslip.bets[props.betslip.bets.length - 1].price.decimal, locale, true)}
                {addPlaceholderColumn(props.showBetslipPlusButton)}
              </div>
            </div>
          </div>
        );

      case BetslipDisplayType.Events:
      default:
        return props.betslip.bets[props.betslip.bets.length - 1].selections.map((selection: ISelection) => (
          <div className="bet-row" key={selection.selectionId}>
            <div className="bet-row__event">
              <div className="bet-row__event--pick">{getSelection(selection, Config.settings.showTeamNameInBetslip, t)}</div>
              <div>{selection.marketCode ? t(selection.marketCode) : ''}</div>
              <div>{selection.eventName ? getEventName(selection.eventName, selection.eventHomeTeamName, selection.eventAwayTeamName, t) : ''}</div>
            </div>
            <div className="bet-row__odds">{numberUtilities.formatNumberToLocale(selection.price.decimal, locale, true)}</div>
            <div className="bet-row__cancel" onClick={() => handleCancelIconClick(selection.selectionId)}></div>
          </div>
        ));
    }
  };

  const renderTaxInfo = () => {
    return (
      <>
        {props.betStakeTaxRate ? (
          <div className={`bet-form__row ${expandedSlip ? null : 'collapsed'}`}>
            <div className="bet-form__row--name bet-form__winnings-row">
              {t('stake-tax')} ({props.betStakeTaxRate * 100}%):
            </div>
            <div id="win-box" className="bet-form__row--number bet-form__winnings-row">
              {currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(stakeTaxAmount, props.devalueAmount), props.currency, locale)}
              {addPlaceholderColumn(props.showBetslipPlusButton)}
            </div>
          </div>
        ) : null}

        {props.betWinningsTaxRate ? (
          <div className={`bet-form__row ${expandedSlip ? null : 'collapsed'}`}>
            <div id="winning-tax" className="bet-form__row--name bet-form__winnings-row">
              {t('winnings-tax')} ({props.betWinningsTaxRate * 100}%):
            </div>
            <div id="win-box" className="bet-form__row--number bet-form__winnings-row tax-value">
              {currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(winningsTaxAmount, props.devalueAmount), props.currency, locale)}
              {addPlaceholderColumn(props.showBetslipPlusButton)}
            </div>
          </div>
        ) : null}

        {props.showBetslipChevronButton && (props.betStakeTaxRate || props.betWinningsTaxRate) ? (
          <div className={`bet-form__row bet-form__winnings-row ${expandedSlip ? null : 'collapsed'}`}>
            <div id="total-returns" className="bet-form__row--name bet-form__winnings-row">
              {t('total-potential-return')}:
            </div>
            <div id="win-box" className="bet-form__row--number bet-form__winnings-row returns">
              {currencyUtilities.formatCurrencyWithOrientation(winnings, props.currency, locale)}
              {addPlaceholderColumn(props.showBetslipPlusButton)}
            </div>
          </div>
        ) : null}
        {props.betStakeTaxRate ? (
          <div className={`bet-form__row ${expandedSlip ? null : 'collapsed'}`}>
            <p className="bet-form__text">
              {t('as-per-government-requirements')}, {props.betStakeTaxRate * 100}% {t('stake-tax-will-be-applied')}
            </p>
          </div>
        ) : null}

        {props.betWinningsTaxRate ? (
          <div className={`bet-form__row ${expandedSlip ? null : 'collapsed'}`}>
            <p className="bet-form__text">
              {t('as-per-government-requirements')}, {props.betWinningsTaxRate * 100}% {t('winning-tax-will-be-applied')}
            </p>
          </div>
        ) : null}
        <div className={`bet-form__row ${expandedSlip ? null : 'collapsed'}`}>
          <p className="bet-form__text bet-form__text--normal">
            {`${t('by-placing-a-bet-you-accept-the')} `}
            <span className="bet-form__link" onClick={() => handleTermsAndConditionsLink()}>
              {t('t-c')}.
            </span>
            {` ${t('limits-may-be-applicable-on-your-winnings')} `}
            <b>
              {t('a-minimum-bet-of-is-required', {
                currency: currencySymbol,
                betMinStake: numberUtilities.formatNumberToLocale(getDevaluedAmount(props.betMinStake, props.devalueAmount), locale, true)
              })}
            </b>
          </p>
        </div>
      </>
    );
  };

  const expandBetslip = () => {
    const onExpandIconClick = () => setExpandedSlip(!expandedSlip);

    if (props.betWinningsTaxRate || props.betStakeTaxRate) {
      return (
        <span className="bet-form__row--expand-button" onClick={onExpandIconClick}>
          {!expandedSlip ? <PlusIcon /> : <MinusIcon />}
        </span>
      );
    } else {
      return null;
    }
  };

  const expandBetSlipChevron = () => {
    const onExpandIconClick = () => setExpandedSlip(!expandedSlip);
    const displayChevronBetslipButton = (props.betWinningsTaxRate || props.betStakeTaxRate) && props.showBetslipChevronButton;

    if (displayChevronBetslipButton) {
      return (
        <span className="bet-form__row--chevron-button" onClick={onExpandIconClick}>
          {!expandedSlip ? <ArrowDown /> : <ArrowUp />}
        </span>
      );
    }
  };

  const getBetForm = () => {
    return (
      <div className="bet-form">
        <div className="bet-form__input-row">
          {props.defaultStakes?.map((autoStake: number) => {
            return (
              <div
                className={`bet-form__input-row--auto-stake ${
                  (props.devalueAmount > 0 ? parseInt(stake) * props.devalueAmount : parseInt(stake)) === autoStake ? 'bet-form__input-row--auto-stake-selected' : ''
                }`}
                key={autoStake}
                onClick={() => handleAutoStakeClick(autoStake)}
              >
                {Config.settings.showCurrencyOnAutoStake
                  ? currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(autoStake, props.devalueAmount), props.currency, locale, true, true)
                  : getDevaluedAmount(autoStake, props.devalueAmount)}
              </div>
            );
          })}
          <div className="bet-form__input-row--currency">{currencySymbol}</div>
          <div className="bet-form__input-row--input">
            <input type="text" name="bet-box" id="bet-box" onChange={(e) => handleBetTextBoxChange(e, props.betMaxStake)} value={stake} />
          </div>
        </div>

        <div className="bet-form-wrapper">
          {props.betslipDisplayType === BetslipDisplayType.Events && props.betslip.bets.length ? (
            <div className="bet-form__row">
              <div className="bet-form__row--name">{t('total-odds')}:</div>
              <div className="bet-form__row--number">
                {numberUtilities.formatNumberToLocale(props.betslip.bets[props.betslip.bets.length - 1].price.decimal, locale, true)}
                {addPlaceholderColumn(props.showBetslipPlusButton)}
              </div>
            </div>
          ) : (
            ''
          )}

          {props.betTypePriceBonuses.length ? (
            <div className="bet-form__row">
              <div className="bet-form__row--name bet-form__bonus-row">
                {t('bonus')} ({bonusPercentage}%):
              </div>
              <div className="bet-form__row--number bet-form__bonus-row">
                <span>{currencyUtilities.formatCurrencyWithOrientation(bonusAmount, props.currency, locale)}</span>
                {expandBetslip()}
              </div>
            </div>
          ) : (
            <></>
          )}
          {!props.showBetslipChevronButton && (props.betStakeTaxRate || props.betWinningsTaxRate) ? (
            <div className="bet-form__row">
              <div className="bet-form__row--name bet-form__winnings-row">{t('total-potential-return')}:</div>
              <div id="win-box" className="bet-form__row--number bet-form__winnings-row">
                {currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(totalPotentialReturn, props.devalueAmount), props.currency, locale)}
                {addPlaceholderColumn(props.showBetslipPlusButton)}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="bet-form__row">
            <div className="bet-form__row--name bet-form__winnings-row">
              {t('to-win')}
              {expandBetSlipChevron()}
            </div>
            <div id="win-box" className="bet-form__row--number bet-form__winnings-row">
              {currencyUtilities.formatCurrencyWithOrientation(getDevaluedAmount(totalPotentialReturn, props.devalueAmount), props.currency, locale)}
              {addPlaceholderColumn(props.showBetslipPlusButton)}
            </div>
          </div>

          {props.betWinningsTaxRate || props.betStakeTaxRate ? renderTaxInfo() : null}
        </div>
        {props.error ? <div className="bet-form__error">{getErrorMessage()}</div> : ''}
        <div className="bet-form__row" id="betslip">
          {renderPlaceBetButton()}
        </div>
      </div>
    );
  };

  return (
    <div className="betslip-table">
      <div className="betslip-table__header">{t('betslip')}</div>
      {getRows()}
      {getBetForm()}
    </div>
  );
};

export default BetslipTable;
