import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearBetSearch } from 'store/actions/appActions';
import MainMenu from 'components/MainMenu';
import MyBets from 'components/MyBets';

interface BetHistoryProps {
  clearBetSearch: () => void;
}

const BetHistory = (props: BetHistoryProps) => {
  useEffect(() => {
    return () => {
      props.clearBetSearch();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainMenu />
      <MyBets showHistory={true} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, results, sport } = state.AppReducer;

  return { error, results, sport };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearBetSearch: () => {
      dispatch(clearBetSearch());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetHistory);
