import { IMatch, IBet } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { GameWeekStatus } from 'constants/AppEnums';
import { MatchesTableProps } from '.';

export const selectionIsActive = (match: IMatch, props: MatchesTableProps) => {
  if (match && props.betSearch) {
    const homeTeamName = match.homeTeam.teamName;
    const awayTeamName = match.awayTeam.teamName;
    let currentBet: IBet;

    for (let j = 0; j < props.betSearch.bets.length; j++) {
      currentBet = props.betSearch.bets[j];
      for (let i = 0; i < currentBet.selections.length; i++) {
        if (currentBet.selections[i].eventName?.includes(homeTeamName) && currentBet.selections[i].eventName?.includes(awayTeamName)) {
          return true;
        }
      }
    }
  }

  return false;
};

export const getTimer = (props: MatchesTableProps, matchTime: number) => {
  const matchStartTime: number = props.sport ? props.sport.nextMatchStartTime : 0;
  let startTime: number = matchStartTime ? matchStartTime - Date.now() - (props.sport.matchInterval - props.matches[0].duration) : 0;
  startTime = Math.max(startTime, 0);
  const minutes: number = Math.floor((matchTime > 0 ? matchTime : startTime * (90 / (props.matches[0].duration / 60000))) / 60000) - 1;
  const time = ((minutes < 10 ? '0' + minutes : minutes) + '').split('');
  let firstNumber: string = '';
  let secondNumber: string = '';
  let period: string = '';

  if (props.sport?.currentGameWeekStatus === 'inplay') {
    // 90 is a football match duration, we divide for the duration of the virtual match in minutes
    startTime = startTime * (90 / (props.matches[0].duration / 60000));
    firstNumber = time[1] !== '-' ? (8 - parseInt(time[0])).toString() : '9';
    secondNumber = time[1] !== '-' ? (9 - parseInt(time[1])).toString() : '0';
    if (parseInt(firstNumber) * 10 + parseInt(secondNumber) <= 45) {
      period = props.live ? '1st-half' : 'full-time';
    } else if (parseInt(firstNumber) * 10 + parseInt(secondNumber) > 45) {
      period = props.live ? '2nd-half' : 'full-time';
    } else {
      period = 'full-time';
    }
  }
  return { startTime, numbers: [firstNumber, secondNumber], period };
};

export const eventsFinished = (eventStatuses?: (string | undefined)[]) => {
  return eventStatuses ? !eventStatuses.some((status: string | undefined) => status !== GameWeekStatus.fulltime) : false;
};

export const scoresGenerated = (scores: any, eventsFinished: boolean) => {
  let result = true;

  if (!eventsFinished) return result;

  scores.forEach((score: (number | undefined)[]) => {
    if (score[0] === undefined || score[1] === undefined) result = false;
  });

  return result;
};
