import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './MainMenuSelector.scss';

interface IMainMenuSelectorPropsOption {
  value: string;
  link: string;
}

export interface IMainMenuSelectorProps {
  options: IMainMenuSelectorPropsOption[];
}

const MainMenuSelector = (props: IMainMenuSelectorProps) => {
  const { t } = useTranslation();
  const [toggleoptions, setToggleoptions] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<string>();

  const handleClick = (event: any) => {
    //if menu is not clicked on hide the menu
    if (!(event.target.id === 'menu' || event.target.id === 'menu_items')) {
      setToggleoptions(!toggleoptions);
    }
  };

  const handleOptionChange = (option: IMainMenuSelectorPropsOption, props: IMainMenuSelectorProps) => {
    //if menu is not clicked on hide the menu
    setCurrentOption(option.value);
  };

  useEffect(() => {
    if (props.options) {
      const optionsValueText = props.options.find((option: IMainMenuSelectorPropsOption) => option.link === window.location.pathname)?.value ?? undefined;
      setCurrentOption(optionsValueText ?? ' ');
    }
  }, [props, props.options]);

  useEffect(() => {
    if (toggleoptions) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleoptions]);

  return (
    <nav className="main-menu-selector">
      {props.options && currentOption ? (
        <div
          id="menu"
          className={`main-menu-selector__select${toggleoptions ? ' main-menu-selector__select--active' : ''}`}
          onClick={() => {
            setToggleoptions(!toggleoptions);
          }}
        >
          {t(currentOption)}
          <div className={`main-menu-selector__options-wrapper${toggleoptions ? ' main-menu-selector__options-wrapper--active' : ''}`}>
            {props?.options.map((option: IMainMenuSelectorPropsOption, index: number) => {
              return (
                <NavLink
                  id="menu_items"
                  key={`${option.value}_${index}`}
                  to={{ pathname: option.link, search: window.location.search }}
                  activeClassName="active"
                  className={`main-menu-selector__option ${currentOption === option.value ? 'main-menu-selector__option--selected' : ''}`}
                  onClick={() => {
                    handleOptionChange(option, props);
                    setToggleoptions(!toggleoptions);
                  }}
                >
                  {t(option.value)}
                </NavLink>
              );
            })}
          </div>
        </div>
      ) : (
        <span className="main-menu-selector--error">{t('currently-no-competitions-available')}</span>
      )}
    </nav>
  );
};

export default MainMenuSelector;
