import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import routes from 'constants/routes';
import Header from 'components/Header';
import Error from 'components/Pages/Error';
import Matches from 'components/Pages/Matches';
import Live from 'components/Pages/Live';
import Result from 'components/Pages/Result';
import LeagueTable from 'components/Pages/LeagueTable';
import BetHistory from 'components/Pages/BetHistory';
import BetReceipt from 'components/Pages/BetReceipt';

import './Routes.scss';

export default function Routes(): any {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Redirect exact from={routes.HOME.route} to={`${routes.MATCHES.route}${window.location.search}`} />
        <Route exact path={routes.MATCHES.route} component={Matches} />
        <Route exact path={routes.LIVE.route} component={Live} />
        <Route exact path={routes.RESULT.route} component={Result} />
        <Route exact path={routes.LEAGUETABLE.route} component={LeagueTable} />
        <Route exact path={routes.BETHISTORY.route} component={BetHistory} />
        <Route exact path={routes.ERROR.route} component={Error} />
        <Route exact path={routes.BETRECEIPT.route} component={BetReceipt} />
        <Route path="*" component={Error} />
      </Switch>
    </BrowserRouter>
  );
}
