import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { IBetConfig, IBetSearch, ICurrencyConfig } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';

import { getBetConfig, getBetHistory } from 'store/actions/appActions';
import routes from 'constants/routes';
import MessageDisplay from 'components/MessageDisplay';
import VirtualHistoryLink from 'components/VirtualHistoryLink';
import EmptyPage from 'components/EmptyPage';
import BetsTable from 'components/MyBets/BetsTable';
import Config from 'theme/config.json';

import './MyBets.scss';

/**
 * This component can be used in 2 different ways
 * IF showHistory is true, it will display all the bets with pagination
 * IF NOT, it will display only the bets related to a game week with no pagination, all at once
 */

interface MyBetsProps {
  error: any;
  betSearch: IBetSearch;
  showHistory: boolean;
  fromTime: number;
  toTime: number;
  waitForSettled: boolean;
  betConfig: IBetConfig;
  getBetHistory: (token: string, page: number, from: number, to: number, waitForSettled: boolean, customerId?: string, countryCode?: string) => void;
  getBetConfig: () => void;
}

const MyBets = (props: MyBetsProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState<boolean>(false);
  const [noBets, setNoBets] = useState<boolean>(false);
  const [currencyConfig, setCurrencyConfig] = useState<ICurrencyConfig>();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (Config.settings.allowParentChanges) {
      window.parent.scrollTo(0, 0);
    } else {
      communicationUtilities.sendPostMessage.scrollTo(0);
    }

    props.getBetConfig();

    let checkBets = setTimeout(() => setNoBets(props.betSearch && props.betSearch?.bets?.length === 0), 3000);
    return () => {
      clearTimeout(checkBets);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currencyToUse = props.betConfig?.currencyConfigs?.find((config) => config.currencyCode === userContext.user.currency);

    if (currencyToUse) {
      setCurrencyConfig(currencyToUse);
    } else {
      if (props.betConfig) {
        const defaultCurrencyConfig: ICurrencyConfig = {
          currencyCode: props.betConfig.betDefaultCurrencyCode,
          defaultStakes: props.betConfig.betDefaultStakes,
          maxPayout: props.betConfig.betMaxPayout,
          maxStake: props.betConfig.betMaxStake,
          minStake: props.betConfig.betMinStake,
          devalueAmount: 0
        };

        setCurrencyConfig(defaultCurrencyConfig);
      }
    }
  }, [props.betConfig, userContext.user.currency]);

  useEffect(() => {
    if (props.betSearch) {
      if (props.betSearch.token && props.betSearch.token !== '') {
        userContext.setToken(props.betSearch.token);
      }

      if (props.betSearch?.paging?.hasNextPage) {
        setDisableNextButton(false);
      }

      if (props.betSearch?.paging?.hasPreviousPage) {
        setDisablePreviousButton(false);
      }
    }

    let getHistory = setTimeout(() => {
      if (userContext.user.token) {
        props.getBetHistory(userContext.user.token, page, props.fromTime, props.toTime, props.waitForSettled, userContext.user.customerId, userContext.user.countryCode);
      }
    }, 500);

    return () => {
      clearTimeout(getHistory);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.fromTime, props.toTime]);

  const handleNextButtonClick = () => {
    setDisableNextButton(true);
    if (props.betSearch.paging.hasNextPage) {
      setPage(page + 1);
      if (Config.settings.allowParentChanges) {
        window.parent.scrollTo(0, 0);
      } else {
        communicationUtilities.sendPostMessage.scrollTo(0);
      }
    }
  };

  const handlePreviousButtonClick = () => {
    setDisablePreviousButton(true);
    if (props.betSearch?.paging?.hasPreviousPage) {
      setPage(page - 1);
      if (Config.settings.allowParentChanges) {
        window.parent.scrollTo(0, 0);
      } else {
        communicationUtilities.sendPostMessage.scrollTo(0);
      }
    }
  };

  const getTableControls = () => {
    if (props.showHistory) {
      return (
        <div className="my-bets__table-controls">
          {props.betSearch?.paging?.hasPreviousPage ? (
            <button className="my-bets__control-button my-bets__control-button--previous" onClick={handlePreviousButtonClick} disabled={disablePreviousButton}>
              {t('previous')}
            </button>
          ) : null}
          {props.betSearch.paging.hasNextPage ? (
            <button className="my-bets__control-button my-bets__control-button--next" onClick={handleNextButtonClick} disabled={disableNextButton}>
              {t('next')}
            </button>
          ) : null}
        </div>
      );
    } else {
      return <VirtualHistoryLink />;
    }
  };

  const getLoadingMessage = () => {
    return (
      <>
        {props.showHistory ? (
          <>
            {noBets ? (
              <MessageDisplay buttonLinkTo={routes.MATCHES.route} buttonText={t('back-to-matches')} displayButton={true}>
                {t('no-bets-in-history')}
              </MessageDisplay>
            ) : (
              <EmptyPage smallVersion={true}>{t('grabbing-your-bets')}</EmptyPage>
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  // TODO Add proper loading
  return (
    <div className="my-bets">
      {props.betSearch && props.betSearch?.bets?.length > 0 ? (
        <>
          <BetsTable
            betSearch={props.betSearch}
            showHistory={props.showHistory}
            devalueAmount={currencyConfig?.devalueAmount ?? 0}
            showTeamNameInMyBets={Config.settings.showTeamNameInMyBets}
            betMaxPayout={currencyConfig?.maxPayout}
          />
          {props.showHistory ? getTableControls() : null}
        </>
      ) : (
        <>{getLoadingMessage()}</>
      )}
      {!props.showHistory ? getTableControls() : ''}
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { error, betSearch, betConfig } = state.AppReducer;

  return { error, betSearch, betConfig };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBetHistory: (token: string, page: number, from: number, to: number, waitForSettled: boolean, customerId?: string, countryCode?: string) => {
      dispatch(getBetHistory(token, page, from, to, waitForSettled, customerId, countryCode));
    },
    getBetConfig: () => {
      dispatch(getBetConfig());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBets);
