import ReactGA from 'react-ga4';
import { IBetSearch, IBetslip, IBet, ISport, ICompetition, IResults, IMatch, IMarket, IBetConfig } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import {
  SET_APP_STATUS,
  SET_BET_ACTIVE,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAILURE,
  SET_COMPETITION_ID,
  GET_SPORT_SUCCESS,
  GET_SPORT_FAILURE,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_FAILURE,
  SET_MARKET,
  GET_RESULTS_SUCCESS,
  GET_RESULTS_FAILURE,
  GET_BET_HISTORY_FAILURE,
  GET_BET_HISTORY_SUCCESS,
  GET_BETSLIP_FAILURE,
  GET_BETSLIP_SUCCESS,
  GET_LEAGUE_TABLE_SUCCESS,
  GET_LEAGUE_TABLE_FAILURE,
  POST_BET_PLACE_SUCCESS,
  POST_BET_PLACE_FAILURE,
  CLEAR_BET_ACTIVE,
  CLEAR_BET_PLACED,
  CLEAR_BETSLIP,
  CLEAR_BET_SEARCH,
  GET_BET_CONFIG_SUCCESS,
  GET_BET_CONFIG_FAILURE,
  GET_USER_TOKEN_SUCCESS,
  GET_USER_TOKEN_FAILURE,
  CLEAR_RESULTS
} from 'store/types';

import GameService from 'services/sportService';
import BetService from 'services/betService';
import UserService from 'services/userService';

const sportService = new GameService();
const betService = new BetService();
const userService = new UserService();

export const setAppStatus = (status: string): object => ({
  type: SET_APP_STATUS,
  status
});

export const setMarket = (selectedMarket: IMarket) => ({
  type: SET_MARKET,
  selectedMarket
});

export const setCompetitionId = (competitionId: number) => ({
  type: SET_COMPETITION_ID,
  competitionId
});

export const setBetActive = (betActive: boolean) => ({
  type: SET_BET_ACTIVE,
  betActive
});

export const getCompetitorsSuccess = (competitions: ICompetition[]) => ({
  type: GET_COMPETITIONS_SUCCESS,
  competitions
});
export const getCompetitorsFailure = (error: String) => ({
  type: GET_COMPETITIONS_FAILURE,
  error
});

export const getSportSuccess = (sport: ISport) => ({
  type: GET_SPORT_SUCCESS,
  sport
});
export const getSportFailure = (error: String) => ({
  type: GET_SPORT_FAILURE,
  error
});

export const getMatchesSuccess = (matches: IMatch[]) => ({
  type: GET_MATCHES_SUCCESS,
  matches
});
export const getMatchesFailure = (error: String) => ({
  type: GET_MATCHES_FAILURE,
  error
});

export const getResultsSuccess = (results: IResults) => ({
  type: GET_RESULTS_SUCCESS,
  results
});
export const getResultsFailure = (error: String) => ({
  type: GET_RESULTS_FAILURE,
  error
});

export const getBetHistorySuccess = (betSearch: IBetSearch) => ({
  type: GET_BET_HISTORY_SUCCESS,
  betSearch
});
export const getBetHistoryFailure = (error: String) => ({
  type: GET_BET_HISTORY_FAILURE,
  error
});

export const getBetslipSuccess = (betslip: IBetslip) => ({
  type: GET_BETSLIP_SUCCESS,
  betslip
});
export const getBetslipFailure = (error: String) => ({
  type: GET_BETSLIP_FAILURE,
  error
});

export const getLeagueTableSuccess = (leagueTable: Array<any>) => ({
  type: GET_LEAGUE_TABLE_SUCCESS,
  leagueTable
});
export const getLeagueTableFailure = (error: String) => ({
  type: GET_LEAGUE_TABLE_FAILURE,
  error
});

export const postBetPlaceSuccess = (bet: IBet, token: string | undefined) => ({
  type: POST_BET_PLACE_SUCCESS,
  bet,
  token
});
export const postBetPlaceFailure = (error: String) => ({
  type: POST_BET_PLACE_FAILURE,
  error
});

export const getBetConfigSuccess = (betConfig: IBetConfig) => ({
  type: GET_BET_CONFIG_SUCCESS,
  betConfig
});
export const getBetConfigFailure = (error: String) => ({
  type: GET_BET_CONFIG_FAILURE,
  error
});

export const clearBetActive = () => ({ type: CLEAR_BET_ACTIVE });
export const clearBetPlaced = () => ({ type: CLEAR_BET_PLACED });
export const clearBetslip = () => ({ type: CLEAR_BETSLIP });
export const clearBetSearch = () => ({ type: CLEAR_BET_SEARCH });
export const clearResults = () => ({ type: CLEAR_RESULTS });

export const getUserTokenSuccess = (token: string) => ({
  type: GET_USER_TOKEN_SUCCESS,
  token
});
export const getUserTokenFailure = (error: String) => ({
  type: GET_USER_TOKEN_FAILURE,
  error
});

export const getCompetitors = (sportId: number) => {
  return (dispatch: any) => {
    sportService
      .getCompetitors(sportId)
      .then((response: any) => {
        dispatch(getCompetitorsSuccess(response.data?.competitions));
      })
      .catch((error: any) => {
        dispatch(getCompetitorsFailure(error));
      });
  };
};

export const getSport = (sportId: number) => {
  return (dispatch: any) => {
    sportService
      .getSport(sportId)
      .then((sportResponse: any) => {
        // should never happen but in case it does, don't request matches
        if (sportResponse.data.currentGameWeekId > sportResponse.data.lastGameWeekId) {
          dispatch(getSportSuccess(sportResponse.data));
          return;
        }
        let gameWeekId = sportResponse.data.currentGameWeekId;
        if (sportResponse.data.currentGameWeekStatus === 'inplay' && gameWeekId !== sportResponse.data.lastGameWeekId) {
          gameWeekId += 1;
        }
        sportService
          .getMatches(sportId, gameWeekId)
          .then((matchesResponse: any) => {
            dispatch(getMatchesSuccess(matchesResponse.data.matches));
            dispatch(getSportSuccess(matchesResponse.data));
          })
          .catch((error: any) => {
            dispatch(getMatchesFailure(error));
          });
      })
      .catch((error: any) => {
        dispatch(getSportFailure(error));
      });
  };
};

export const getResults = (sportId: number, weekId: number) => {
  return (dispatch: any) => {
    sportService
      .getResults(sportId, weekId)
      .then((response: any) => {
        dispatch(getResultsSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getResultsFailure(error));
      });
  };
};

export const getBetHistory = (token: string, page: number = 1, from: number = 0, to: number = 0, waitForSettled: boolean = false, customerId?: string, countryCode?: string) => {
  return (dispatch: any) => {
    // FIXME This check will be different when we have a proper login system, this is to avoid 400 errors if there is no token
    if (!token) return null;

    // BUGFIX: The API takes some time to settle the bet. The following logic polls the API until no bets are returned or the latest bet is settled.
    let backoff: number = 500;
    let attemptsLeft: number = 5;
    (function action() {
      betService
        .getBetHistory(token, page, from, to, customerId, countryCode)
        .then((response: any) => {
          if (!waitForSettled || response?.data?.bets?.[0]?.settled !== false) {
            dispatch(getBetHistorySuccess(response.data));
          } else {
            if (attemptsLeft > 0) {
              setTimeout(action, backoff);

              backoff *= 2;
              attemptsLeft -= 1;
            }
          }
        })
        .catch((error: any) => {
          dispatch(getBetHistoryFailure(error));
        });
    })();
  };
};

export const getLeagueTable = (sportId: number, weekId: number) => {
  return (dispatch: any) => {
    sportService
      .getLeagueTable(sportId, weekId)
      .then((response: any) => {
        dispatch(getLeagueTableSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getLeagueTableFailure(error));
      });
  };
};

export const getBetslip = (selections: string[]) => {
  return (dispatch: any) => {
    betService
      .getBetslip(selections)
      .then((response: any) => {
        dispatch(getBetslipSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getBetslipFailure(error));
      });
  };
};

export const postBetPlace = (token: string, bets: IBet[], customerId?: string, countryCode?: string) => {
  return (dispatch: any) => {
    betService
      .postBetPlace(token, bets, customerId, countryCode)
      .then((response: any) => {
        if ((response.data.rejectionCode || response.data.bets[0].rejectionCode) === undefined) {
          ReactGA.event({
            category: 'Bet slip',
            action: 'Success'
          });
          dispatch(postBetPlaceSuccess(response.data.bets[0], response.data.token));
          dispatch(setBetActive(true));
        } else {
          ReactGA.event({
            category: 'Bet slip',
            action: 'Failure',
            label: response.data.bets ? response.data.bets[0].rejectionReason : response.data.rejectionReason
          });
          dispatch(postBetPlaceFailure(response.data.bets ? response.data.bets[0].rejectionCode : response.data.rejectionCode));
        }
      })
      .catch((error: any) => {
        dispatch(postBetPlaceFailure(error.errorCode !== undefined ? error.errorCode : error));
      });
  };
};

export const getBetConfig = () => {
  return (dispatch: any) => {
    betService
      .getBetConfig()
      .then((response: any) => {
        dispatch(getBetConfigSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getBetConfigFailure(error));
      });
  };
};

export const getUserToken = (username: string, password: string) => {
  return (dispatch: any) => {
    userService
      .getUserToken(username, password)
      .then((response: any) => {
        dispatch(getUserTokenSuccess(response.data.token));
      })
      .catch((error: any) => {
        dispatch(getUserTokenFailure(error));
      });
  };
};
