import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ISport, IResults } from '@incentivegames/ig-types/lib/interfaces/apiContracts';

import { getResults, clearBetSearch, clearResults } from 'store/actions/appActions';
import MainMenu from 'components//MainMenu';
import MatchesTable from 'components/MatchesTable';
import EmptyPage from 'components/EmptyPage';
import Config from 'theme/config.json';
import MyBets from 'components/MyBets';

interface LiveProps {
  error: any;
  sport: ISport;
  results: IResults;
  getResults: (sportId: number, weekId: number) => void;
  clearBetSearch: () => void;
  clearResults: () => void;
}

const Live = (props: LiveProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      props.clearBetSearch();
      props.clearResults();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.sport) props.getResults(props.sport.competitionId, props.sport.currentGameWeekId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sport]);

  useEffect(() => {
    const updateResult = setInterval(() => {
      if (props.sport && props.sport.currentGameWeekStatus === 'inplay') {
        props.getResults(props.sport.competitionId, props.sport.currentGameWeekId);
      }
    }, Config.settings.resultsRequestFrequency);

    return () => clearInterval(updateResult);
  });

  return (
    <>
      <MainMenu />
      <div className="page">
        {props.sport && props.results ? (
          <MatchesTable sport={props.sport} matches={props.results?.matches} live={true} />
        ) : (
          <>{props.error ? <EmptyPage>{t('currently-no-match-available')}</EmptyPage> : <EmptyPage smallVersion={true}>{t('grabbing-scores-now')}</EmptyPage>}</>
        )}
      </div>
      {props.results ? <MyBets showHistory={false} fromTime={props.results.matches[0].startTime - props.results.matchInterval} toTime={props.results.matches[0].startTime} /> : ''}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { error, results, sport } = state.AppReducer;

  return { error, results, sport };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getResults: (sportId: number, weekId: number) => {
      dispatch(getResults(sportId, weekId));
    },
    clearBetSearch: () => {
      dispatch(clearBetSearch());
    },
    clearResults: () => {
      dispatch(clearResults());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Live);
