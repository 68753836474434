import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { validationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/validationUtilities';
import { numberUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/numberUtilities';
import { IMatch, IMarket, ISelection, IBetslip } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { ErrorPopup } from '@incentivegames/ig-component-common';

import { errorUtilities } from 'utilities/ErrorUtilities';
import { TeamType, SelectionErrorDisplayType } from 'constants/AppEnums';
import EmptyPage from 'components/EmptyPage';
import MaxBetsError from 'components/MaxBetsError';
import { swapSelectionName, selection, renderTeamColourOrBadge } from 'components/Pages/Matches/MatchesUtilities';
import Config from 'theme/config.json';

interface MatchesTableProps {
  matches: IMatch[];
  selectedMarket: IMarket;
  selections: selection[] | undefined;
  addOrRemoveSelection: (selectionId: string, add: boolean, matchId: any) => void;
  error: any;
  betslip: IBetslip;
  selectionErrorDisplayType: string;
  maxSelections: number;
}

const MatchesTable = (props: MatchesTableProps) => {
  const { t } = useTranslation();
  const [showMaxSelectionsError, setShowMaxSelectionsError] = useState<boolean>(false);
  const [numberOfSelections, setNumberOfSelections] = useState<number>(0);
  const [currentSelection, setCurrentSelection] = useState<selection>();

  useEffect(() => {
    if (props.betslip?.bets?.length === 0) {
      setShowMaxSelectionsError(false);
    }
    if (props.betslip && props.betslip.bets.length > 0) {
      setNumberOfSelections(props.betslip.bets[props.betslip.bets.length - 1].selections.length);
    } else {
      setNumberOfSelections(0);
    }
    // eslint-disable-next-line
  }, [props.betslip]);

  useEffect(() => {
    if (currentSelection) {
      const isSelectionAlreadySelected = props.selections?.some((selection) => selection.selectionId === currentSelection.selectionId) ?? false;
      const isSelectionInASelectedMatch = props.selections?.some((selection) => selection.matchId === currentSelection.matchId) ?? false;

      if (numberOfSelections >= props.maxSelections && props.selectionErrorDisplayType === SelectionErrorDisplayType.displayOnClick && !isSelectionAlreadySelected) {
        setShowMaxSelectionsError(true);
        // We never want to add, only remove if somehow we've previously managed to add more than the max numof selections
        props.addOrRemoveSelection(currentSelection.selectionId, false, currentSelection.matchId);
      }
      
      //if max number of bets OR selecting one that is already selected OR selecting a different selection within the same match
      if (numberOfSelections < props.maxSelections || isSelectionAlreadySelected || isSelectionInASelectedMatch) {
        setShowMaxSelectionsError(false);
        props.addOrRemoveSelection(currentSelection.selectionId, !isSelectionAlreadySelected ?? false, currentSelection.matchId);
      } else {
        setShowMaxSelectionsError(true);
      }
    }
    // eslint-disable-next-line
  }, [currentSelection]);

  const getErrorPosition = (event: MouseEvent) => {
    if (props.selectionErrorDisplayType !== SelectionErrorDisplayType.displayOnClick) {
      return;
    }
    const wrapper = document.getElementById('error-popup-container') as HTMLElement;
    if (wrapper) {
      wrapper.style.top = event.pageY - window.scrollY + 'px';
    }
  };

  const renderMatchesByMarket = (selectedMarket: IMarket, markets: IMarket[], homeTeam: string, awayTeam: string, t: any, matchId: any) => {
    return markets.map((market: IMarket) => {
      return market.marketCode === selectedMarket?.marketCode
        ? market.selections.map((selection: ISelection) => {
            const selectionAlreadySelected = props.selections ? props.selections.map((s: any) => s.selectionId).indexOf(selection.selectionId) > -1 : false;
            const selectionNotSelected = props.selections ? props.selections.map((s: any) => s.selectionId).indexOf(selection.selectionId) < 0 : false;

            return (
              <div
                className={`match-table__col  ${
                  props.selections
                    ? selectionAlreadySelected
                      ? 'active'
                      : selectionNotSelected && currentSelection?.selectionId === selection.selectionId && showMaxSelectionsError
                      ? 'flashing'
                      : 'inactive'
                    : 'inactive'
                }`}
                onClick={(event: any) => {
                  setCurrentSelection({ matchId: matchId, selectionId: selection.selectionId });
                  getErrorPosition(event);
                }}
                key={selection.selectionId}
                id={selection.selectionId}
              >
                <div className="match-table__selection-name">{swapSelectionName(selection.selectionName, t)}</div>
                <div className="match-table__selection-odds">{numberUtilities.formatNumberToLocale(selection.price.decimal, Config.settings.defaultLocale, true)}</div>
              </div>
            );
          })
        : '';
    });
  };

  const getFavouredTeam = (match: IMatch, team: TeamType) => {
    if (match && props.selectedMarket) {
      const currentMarket = match.markets.find((market: IMarket) => market.marketName === props.selectedMarket.marketName);
      const homeTeamOdds = currentMarket?.selections[0].price.decimal ?? 0;
      const awayTeamOdds = currentMarket?.selections[currentMarket?.selections.length - 1].price.decimal ?? 0;

      switch (team) {
        case TeamType.homeTeam:
          return homeTeamOdds <= awayTeamOdds;
        case TeamType.awayTeam:
          return awayTeamOdds <= homeTeamOdds;
        default:
          return false;
      }
    }
  };

  const handleCloseErrorClick = () => {
    setShowMaxSelectionsError(false);
  };

  const getErrorDisplayType = () => {
    switch (props.selectionErrorDisplayType) {
      case SelectionErrorDisplayType.displayOnClick:
        return (
          <div style={{ visibility: showMaxSelectionsError ? 'visible' : 'hidden' }}>
            {ErrorPopup(errorUtilities.getErrorPopupProps(handleCloseErrorClick, t(`selection-limit-has-been-exceeded`, { maxSelections: props.maxSelections })))}
          </div>
        );
      case SelectionErrorDisplayType.floatFromTop:
        return showMaxSelectionsError ? <MaxBetsError closeFunction={() => setShowMaxSelectionsError(false)} /> : <></>;
      case SelectionErrorDisplayType.none:
      default:
        return <></>;
    }
  };

  if (props.matches) {
    const isSmallMobile = validationUtilities.isScreenSize(
      'smallMobile',
      Config.screenSizes.maxDesktopScreen,
      Config.screenSizes.mobileScreen,
      Config.screenSizes.mediumScreen,
      Config.screenSizes.smallScreen
    );

    return (
      <div className="match-table">
        {getErrorDisplayType()}
        {props.matches.map((match: IMatch) => {
          return (
            <div className="match-table__row" key={match.matchId}>
              <div className="match-table__teams">
                <div className={`match-table__team-home ${getFavouredTeam(match, TeamType.homeTeam) && Config.settings.highlightFavouriteTeam ? 'match-table__favoured-team' : ''}`}>
                  {renderTeamColourOrBadge(match.homeTeam, TeamType.homeTeam)}
                  <span>
                    V-
                    {isSmallMobile ? match.homeTeam.teamShortName : t(match.homeTeam.teamName)}
                  </span>
                </div>
                <div className={`match-table__team-away ${getFavouredTeam(match, TeamType.awayTeam) && Config.settings.highlightFavouriteTeam ? 'match-table__favoured-team' : ''}`}>
                  {renderTeamColourOrBadge(match.awayTeam, TeamType.awayTeam)}
                  <span>
                    V-
                    {isSmallMobile ? match.awayTeam.teamShortName : t(match.awayTeam.teamName)}
                  </span>
                </div>
              </div>
              <div className="match-table__col-wrapper">{renderMatchesByMarket(props.selectedMarket, match.markets, match.homeTeam.teamShortName, match.awayTeam.teamShortName, t, match.matchId)}</div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <>{props.error ? <EmptyPage>{t('currently-no-match-available')}</EmptyPage> : <EmptyPage smallVersion={true}>{t('grabbing-odds-now')}</EmptyPage>}</>;
  }
};

export default MatchesTable;
