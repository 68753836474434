import axios from 'axios';
import { IBet } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import appConstants from 'constants/appConstants';
import { getHeaders } from './servicesUtilities';

const baseUrl = process.env.REACT_APP_BET_API_URL || '/betapi';

// TODO Add token when the log in system is implemented. For testing, we retrieve it from the cookies.
export default class BetService {
  getBetHistory(token: string, page: number, from: number, to: number, customerId?: string, countryCode?: string): Promise<Object> {
    // If TO is in the future we don't send the parameter to avoid the error
    const toFixed = Date.now() <= to ? '' : `&toTime=${to}`;

    return axios({
      method: 'get',
      url:
        from !== 0
          ? `${baseUrl}/betsearch?fromTime=${from}${toFixed}&pageSize=20&gameType=${appConstants.GAMETYPE}`
          : `${baseUrl}/betsearch?pageNumber=${page}&pageSize=2&gameType=${appConstants.GAMETYPE}`,
      headers: getHeaders(token, customerId, countryCode)
    });
  }

  getBetslip(selections: string[]): Promise<Object> {
    return axios({
      method: 'post',
      url: `${baseUrl}/betslip/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        siteId: process.env.REACT_APP_SITE_ID || '',
        selections: selections?.map((s: any) => {
          return {
            selectionId: s
          };
        })
      }
    });
  }

  postBetPlace(token: string, bets: IBet[], customerId?: string, countryCode?: string): Promise<Object> {
    return axios({
      method: 'post',
      url: `${baseUrl}/betplace`,
      headers: getHeaders(token, customerId, countryCode),
      data: {
        bets
      }
    });
  }

  getBetConfig(): Promise<Object> {
    return axios({
      method: 'get',
      url: `${baseUrl}/betconfig/${process.env.REACT_APP_SITE_ID || ''}?gameType=${appConstants.GAMETYPE}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
