import React from 'react';
import { useTranslation } from 'react-i18next';

import alertIcon from 'theme/assets/images/alert.svg';
import closeIcon from 'theme/assets/images/close.svg';
import Config from 'theme/config.json';

import './MaxBetsError.scss';

interface MaxBetsErrorProps {
    closeFunction: () => void;
}

const MaxBetsError = (props: MaxBetsErrorProps) => {
  const { t } = useTranslation();

  return (
    <div className="max-bets-error">
        <div className="max-bets-error__alert-icon">
            <img src={alertIcon} alt="alert icon"></img>
        </div>
        <div className="max-bets-error__text">{`${t('maximum-of')} ${Config.settings.maxNumberOfBets} ${t('selections-per-bet')}`}</div>
        <div className="max-bets-error__close-icon" onClick={() => props.closeFunction()}>
            <img src={closeIcon} alt="close icon"></img>
        </div>
    </div>
  );
};

export default MaxBetsError;
